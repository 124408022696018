import React from "react";
import useStyles from "../../core/src/hooks/useStyles";
import Header from "../../components/Header";
import Title from "../../core/src/components/Title";
import Spacing from "../../core/src/components/Spacing";
import Text from "../../core/src/components/Text";
import AdaptiveGrid from "../../core/src/components/AdaptiveGrid/index";
import Content from "../../components/Content";
import IconButton from "../../core/src/components/IconButton";
import IconClose from "../../icons/src/interface/IconClose";
import history from "../../history";
import List, { Item as ListItem } from "../../core/src/components/List";
import Divider from "../../core/src/components/Divider";
import Button from "../../core/src/components/Button";

const styleSheet = ({ unit }) => ({
  modal: {
    position: "fixed",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    zIndex: 3,
    visibility: "visible",
    opacity: 1,
    willChange: "opacity, visibility",
    transition: "visibility 0.2s ease 0s, opacity 0.2s ease 0s",
    background: "white"
  },
  modal_open: {
    overflowY: "auto",
    transform: "none",
    willChange: "transform",
    position: "fixed",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.2s cubic-bezier(0.2, 0.91, 0.85, 0.96) 0s"
  },
  model_presentation: {
    overflow: "unset",
    width: "100%",
    boxSizing: "border-box",
    position: "relative",
    textAlign: "center",
    maxHeight: "100vh",
    padding: `0 ${unit * 6}`
  }
});

const Upgrade = ({ match }) => {
  const [styles, cx] = useStyles(styleSheet);

  const handleClose = () => {};

  return (
    <div className={cx(styles.modal)}>
      <Header
        logoDark
        actions={
          <IconButton>
            <IconClose
              accessibilityLabel="Close upgrade screen"
              size={32}
              onClick={history.goBack}
            />
          </IconButton>
        }
      />
      <div className={cx(styles.modal_open)}>
        <div className={cx(styles.model_presentation)}>
          <Spacing vertical={3}>
            <Title level={1}>More features, more power</Title>
            <Text large>Take your product development to the next level</Text>
            <Content xxsmall>
              <div
                className={cx({
                  display: "flex",
                  justifyContent: "center",
                  width: "100%"
                })}
              >
                <List>
                  <ListItem spacious decoration>
                    <Text leading="tight" centerAlign>
                      FOTA updates for BLE and LTE-M devices
                    </Text>
                  </ListItem>
                  <ListItem spacious decoration>
                    <Text leading="tight">
                      Remotely control and gain insight on your products
                    </Text>
                  </ListItem>
                  <ListItem spacious decoration>
                    <Text leading="tight">
                      Keep your product competitive with valuable upgrades
                    </Text>
                  </ListItem>
                  <ListItem spacious decoration>
                    <Text leading="tight">
                      Reduce your development load with production-ready tools
                    </Text>
                  </ListItem>
                </List>
              </div>
              <Spacing vertical={4}>
                <Button
                  block
                  large
                  openInNewWindow
                  external
                  href="https://calendly.com/justaddobvious/15min"
                >
                  Set up a call
                </Button>
              </Spacing>
              <Divider label="Or" />
              <Spacing vertical={4}>
                <Button
                  inverted
                  openInNewWindow
                  external
                  href="https://www.obvious.xyz/platform"
                  block
                  large
                >
                  Try it out
                </Button>
              </Spacing>
            </Content>
          </Spacing>
        </div>
      </div>
    </div>
  );
};

export default Upgrade;
