import React from "react";
import _pt from "prop-types";
import uuid from "uuid/v4";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { RESET_USER_PASSWORD } from "./queries";
import useStyles, { aesthetic } from "../../../core/src/hooks/useStyles";
import useTheme from "../../../core/src/hooks/useTheme";
import IconObviousLogo from "../../../icons/src/interface/IconObviousLogo";
import Title from "../../../core/src/components/Title";
import Input from "../../../core/src/components/Input";
import Spacing from "../../../core/src/components/Spacing";
import Button from "../../../core/src/components/Button";
import Link from "../../../core/src/components/Link";
import IconCaretRight from "../../../icons/src/interface/IconCaretRight";
import Divider from "../../../core/src/components/Divider";
import { validateEmail, primaryColorGenerator } from "../../../helpers";
import Alert from "../../../core/src/components/Alert";
import ResponsiveImage from "../../../core/src/components/ResponsiveImage";
import history from "../../../history";
import Header from "../../../components/Header";

const styleSheet = ({ color, unit }) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minHeight: "100vh"
  },
  header: {
    height: "135px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  content: {
    width: "446px",
    margin: "auto",
    paddingBottom: "135px",
    maxWidth: "90vw",
    verticalAlign: "middle"
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline"
  },
  error_danger: {
    backgroundColor: color.core.danger[2],
    color: color.core.neutral[4]
  },
  form: {
    opacity: 1,
    transition: "all .2s ease-in-out"
  }
});

const Forgot = () => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [id] = React.useState(uuid());
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState();
  const [serverError, setServerError] = React.useState(false);

  const [error, setError] = React.useState({
    email: { status: false }
  });

  const [resetUserPassword] = useMutation(RESET_USER_PASSWORD);

  const updateField = (value, event) => {
    setEmail(value);
    setError({
      ...error,
      [event.target.name]: { status: false }
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    let submitError = false;
    var errors = {};
    if (!email) {
      errors = {
        ...errors,
        email: { status: true }
      };
      submitError = true;
    }

    if (!validateEmail(email)) {
      errors = {
        ...errors,
        email: { status: true }
      };
      submitError = true;
    }

    if (submitError) {
      setError({ ...error, ...errors });
      return;
    }
    setLoading(true);

    resetUserPassword({
      variables: {
        email: email
      }
    })
      .then(
        ({
          data: {
            resetUserPassword: { success }
          }
        }) => {
          if (success) {
            history.push({
              pathname: "/login",
              state: { resetPassword: true }
            });
          }
        }
      )
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <div>
      <Header logoDark />
      <div className={cx(styles.wrapper)}>
        <div className={cx(styles.content)}>
          <div className={cx(styles.form)}>
            <Spacing vertical={4}>
              <div className={cx(styles.title)}>
                <Title level={2}>Forgot your password?</Title>
                <Link
                  href="/login"
                  afterIcon={<IconCaretRight decorative size={24} />}
                >
                  Sign in
                </Link>
              </div>
            </Spacing>
            {(error.email.status || serverError) && (
              <Spacing bottom={4}>
                <Alert title="Invalid Email" danger full hideStatusIcon />
              </Spacing>
            )}
            <form
              id={id}
              method="post"
              encType="multipart/form-data"
              onSubmit={handleSubmit}
            >
              <Spacing bottom={2}>
                <Input
                  name="email"
                  placeholder="Email"
                  label="Email"
                  hideLabel
                  large
                  type="email"
                  value={email}
                  onChange={updateField}
                  autoComplete="email"
                  noSpacing
                  invalid={error.email.status}
                />
              </Spacing>
              <Spacing vertical={4}>
                <Button
                  large
                  block
                  disabled={!email}
                  onClick={handleSubmit}
                  loading={loading}
                  type="submit"
                >
                  Send me a new password
                </Button>
              </Spacing>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

Forgot.propTypes = {};

Forgot.defaultProps = {};

export default Forgot;
