import React from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import Login from "./screens/account/login";
import Product from "./screens/product";
import Team from "./screens/team";
import Forgot from "./screens/account/forgot";
import Reset from "./screens/account/reset";
import history from "./history";
import Create from "./screens/account/create";
import client from "./Apollo";
import { changeThemeColors } from "./helpers";
import { RoutePrivate } from "./components/RoutePrivate/index";
import Group from "./screens/product/groups/Group";
import FirmwareDetail from "./screens/product/firmware/FirmwareDetail";
import Upgrade from "./screens/upgrade";
import Account from "./screens/account/manage";
import Docs from "./screens/docs/index";
import Setup from "./screens/account/setup/index";
import Message from "./screens/account/message";

const publicLinks = [
  "/access",
  "/create",
  "/login",
  "/join",
  "/forgot",
  "/reset"
];

const App = ({ location }) => {
  React.useEffect(() => {
    if (publicLinks.includes(location.pathname)) {
      const token = localStorage.getItem("ocelot-token");
      if (token) {
        if (location.pathname === "/login") {
          localStorage.removeItem("ocelot-token");
          localStorage.removeItem("manufacturer");
          localStorage.removeItem("user");
          client.resetStore();
          changeThemeColors("#00B1A9");
        } else {
          history.push({
            pathname: "/products"
          });
        }
      }
    }
  });

  return (
    <>
      <Switch>
        <Redirect from="/" to="/signin" exact />
        <Redirect from="/login" to="/signin" exact />
        <Route exact path="/create" render={props => <Create {...props} />} />
        <Route exact path="/signin" render={props => <Login {...props} />} />
        <Route exact path="/forgot" render={props => <Forgot {...props} />} />
        <Route exact path="/reset" render={props => <Reset {...props} />} />
        <Route exact path="/setup" render={props => <Setup {...props} />} />
        <Route exact path="/message" render={props => <Message {...props} />} />

        <RoutePrivate path="/product/groups/:group" component={Group} />
        <RoutePrivate
          path="/product/firmware/:firmware"
          component={FirmwareDetail}
        />
        <RoutePrivate path="/product" component={Product} />
        <RoutePrivate path="/team" component={Team} />
        <RoutePrivate path="/upgrade" component={Upgrade} />
        <RoutePrivate path="/account" component={Account} />
        <RoutePrivate path="/docs" component={Docs} />
      </Switch>
    </>
  );
};

export default withRouter(App);
