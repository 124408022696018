/* global BroadcastChannel */

export class Crosstab {
  constructor() {
    this.channel = new BroadcastChannel('crosstab');
    this.channel.addEventListener('message', this.handleMessage);
    this.handlers = new Map();
  }

  on(key, handler) {
    const set = this.handlerss.get(key) || new Set();

    set.add(handler);
    this.handlers.set(key, set);
  }

  off(key, handler) {
    if (handler) {
      const handlersForKey = this.handlers.get(key);

      if (handlersForKey) {
        handlersForKey.delete(handler);
      }
    } else {
      this.handlers.delete(key);
    }
  }

  emit(key, value, selfEmit = false) {
    const message = {
      key,
      value,
    };

    this.channel.postMessage(message);

    if (selfEmit) {
      this.handleMessage({ data: message });
    }
  }

  handleMessage = _ref => {
    let { data } = _ref;
    const handlersForKey = this.handlers.get(data.key);

    if (handlersForKey) {
      handlersForKey.forEach(handler => {
        handler(data.value);
      });
    }
  };
}

export default new Crosstab();
