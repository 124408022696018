import React from "react";
import _pt from "prop-types";
import uuid from "uuid/v4";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { AUTHENTICATION, START_LOGIN } from "./queries";
import { GET_PRODUCT } from "../../product/queries";
import {
  validateEmail,
  changeThemeColors,
  lowercaseNoSpace
} from "../../../helpers";
import useStyles, { aesthetic } from "../../../core/src/hooks/useStyles";
import useTheme from "../../../core/src/hooks/useTheme";
import Header from "../../../components/Header";
import Text from "../../../core/src/components/Text";
import Button from "../../../core/src/components/Button";
import Spacing from "../../../core/src/components/Spacing";
import LeftPanel from "../LeftPanel";
import RightPanel from "../RightPanel";
import FeaturesBanner from "../FeaturesBanner";
import Title from "../../../core/src/components/Title";
import history from "../../../history";
import CheckBox from "../../../core/src/components/CheckBox";
import Link from "../../../core/src/components/Link";
import Input from "../../../core/src/components/Input";
import Alert from "../../../core/src/components/Alert";
import ResponsiveImage from "../../../core/src/components/ResponsiveImage";
import IconObviousLogo from "../../../icons/src/interface/IconObviousLogo";
import IconObviousPlugin from "../../../icons/src/interface/IconObviousPlugin";

const styleSheet = ({ color, unit }) => ({
  panelWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    position: "relative",
    width: "100%"
  },
  feature_panel: {
    display: "flex",
    position: "fixed",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    left: 0,
    width: unit * 57,
    minheight: unit * 75,
    height: "100%",
    overflow: "hidden",
    background: "center center / cover no-repeat rgba(36,40,56,0.65)",
    backgroundPosition: "center center",
    backgroundRepeat: "noRepeat"
  },
  obv_logo: {
    position: "absolute",
    top: unit * 5,
    left: unit * 5,
    zIndex: 1
  },
  feature_panel_image: {
    objectFit: "cover",
    height: "100%",
    position: "absolute",
    right: 0,
    top: 0,
    transition: "all 0.3s liner 0s"
  },
  login_content: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  login_content_wrapper: {
    width: "100%",
    maxWidth: unit * 72,
    padding: 0
  },
  form: {
    opacity: 1,
    transition: "all .2s ease-in-out"
  },
  form_start_login: {
    opacity: 0
  },
  logo: {
    position: "absolute",
    zIndex: 10,
    opacity: 1,
    pointerEvents: "none",
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    left: 0
  },
  logo_outer_circle: {
    width: "256px",
    height: "256px",
    backgroundColor: color.core.primary[3],
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "all .2s ease-in-out"
  },
  logo_outer_circle_expand: {
    width: "100vw",
    height: "100vh",
    borderRadius: "0px"
  },
  logo_wrapper: {
    display: "flex",
    alignItems: "flex-end",
    willChange: "transform, opacity",
    opacity: 0,
    transform: "translateY(-100%)",
    transition: "all .4s ease-in-out"
  },
  logo_wrapper_show: {
    transform: "translateY(0)",
    opacity: 1
  },
  logo_inner_circle: {
    width: "128px",
    height: "128px",
    backgroundColor: color.base,
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  logo_inner_circle_expand: {
    width: 200,
    height: "auto",
    backgroundColor: "transparent"
  },
  logo_dot: {
    width: "56px",
    height: "56px",
    backgroundColor: "#000000",
    borderRadius: "100%",
    marginBottom: "16px"
  },
  logo_dot_hide: {
    width: 0,
    height: 0
  },
  logo_company: {
    opacity: 0,
    transition: "all .4s ease-in-out"
  },
  logo_company_show: {
    opacity: 1
  },
  noshow: {
    transition: "all .2s ease-in-out",
    opacity: 1
  },
  show: {
    opacity: 0
  }
});

const Login = ({ location }) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [id] = React.useState(uuid());
  const [loading, setLoading] = React.useState(false);
  const [checkAuthentication] = useMutation(AUTHENTICATION);
  const [loginStart] = useMutation(START_LOGIN);
  const [remember, setRemember] = React.useState(false);
  const [login, setLogin] = React.useState({
    email: "",
    password: ""
  });

  const [error, setError] = React.useState({
    email: { status: false },
    password: { status: false }
  });

  const [serverError, setServerError] = React.useState({
    status: false,
    msg: ""
  });
  const [serverSuccess, setServerSuccess] = React.useState({
    status: false,
    msg: ""
  });
  const [nextstep, setNextstep] = React.useState(null);
  const [ready, setReady] = React.useState(false);
  const [startLogin, setStartLogin] = React.useState(false);
  const [expand, setExpand] = React.useState(false);
  const [company, setCompany] = React.useState(false);
  const [manufacturer, setManufacturer] = React.useState();

  React.useEffect(() => {
    if (location.state && location.state.accountCreated) {
      setServerSuccess({
        status: true,
        msg: "Login was successfully created"
      });
      history.replace({
        pathname: "/login",
        state: {}
      });
    }
    if (location.state && location.state.resetPassword) {
      setServerSuccess({
        status: true,
        msg:
          "If your email exists in our database, you'll receive a reset link shortly."
      });
      history.replace({
        pathname: "/login",
        state: {}
      });
    }
    if (location.state && location.state.success) {
      setServerSuccess({ status: true, msg: location.state.msg });
      history.replace({
        pathname: "/login",
        state: {}
      });
    }
    if (location.state && location.state.error) {
      setServerError({ status: true, msg: location.state.msg });
      history.replace({
        pathname: "/login",
        state: {}
      });
    }
  });

  const updateField = (value, event) => {
    setLogin({
      ...login,
      [event.target.name]: value
    });
    setError({
      ...error,
      [event.target.name]: { status: false }
    });
  };

  React.useEffect(() => {
    if (ready && nextstep) {
      history.push(nextstep);
    }
  });

  const handleSubmit = event => {
    event.preventDefault();
    let submitError = false;
    var errors = {};
    if (!login.email) {
      errors = {
        ...errors,
        email: { status: true }
      };
      submitError = true;
    }

    if (!validateEmail(login.email)) {
      errors = {
        ...errors,
        email: { status: true }
      };
      submitError = true;
    }

    if (!login.password) {
      errors = {
        ...errors,
        password: { status: true }
      };
      submitError = true;
    }

    if (submitError) {
      setError({ ...error, ...errors });
      setServerError({ status: true, msg: "Invalid Email or Password" });
      return;
    }
    setLoading(true);

    checkAuthentication({
      variables: {
        email: login.email,
        password: login.password
      }
    })
      .then(({ data: { tokenAuth } }) => {
        const seenDocs = localStorage.getItem("seen-docs");
        const manufacturer = {
          name: tokenAuth.manufacturer.name,
          color: tokenAuth.manufacturerColor.value,
          logo: tokenAuth.manufacturerLogo.value
        };
        const user = {
          firstName: tokenAuth.user.firstName,
          lastName: tokenAuth.user.lastName,
          email: tokenAuth.user.email,
          userType: tokenAuth.userType.name
        };
        loginStart()
          .then(() => {
            if (!seenDocs) {
              setNextstep("/docs");
            } else {
              setNextstep("/product");
            }
          })
          .catch(res => {
            if (user.userType === "admin") {
              setNextstep("/setup");
            } else {
              setNextstep("/message");
            }
          });
        setStartLogin(true);
        user.userType = lowercaseNoSpace(user.userType);
        localStorage.setItem("ocelot-token", tokenAuth.token);
        localStorage.setItem("manufacturer", JSON.stringify(manufacturer));
        localStorage.setItem("user", JSON.stringify(user));
        setManufacturer(manufacturer);
        setTimeout(() => {
          setExpand(true);
        }, 1000);

        setTimeout(() => {
          setCompany(true);
        }, 1250);

        setTimeout(() => {
          setReady(true);
        }, 2000);
      })
      .catch(() => {
        setServerError({ status: true, msg: "Invalid Email or Password" });
        setServerSuccess({ status: false, msg: "" });
        setLoading(false);
      });
  };

  return (
    <div className={cx(styles.panelWrapper)}>
      <Header
        className={cx(styles.noshow, startLogin && styles.show)}
        actions={
          <>
            <Text>
              Don't have an Login?
              <Spacing inline left={3}>
                <Button href="/create" small inverted>
                  Sign up
                </Button>
              </Spacing>
            </Text>
          </>
        }
      />
      <LeftPanel className={cx(styles.noshow, startLogin && styles.show)}>
        <FeaturesBanner />
      </LeftPanel>
      <RightPanel className={cx(styles.noshow, startLogin && styles.show)}>
        <div className={cx(styles.login_content)}>
          <div className={cx(styles.login_content_wrapper)}>
            <Spacing bottom={4}>
              <div>
                <Title level={2}>Sign in</Title>
                <Text>Enter your details below</Text>
              </div>
            </Spacing>
            <form
              id={id}
              method="post"
              encType="multipart/form-data"
              onSubmit={handleSubmit}
            >
              {(error.email.status ||
                error.password.status ||
                serverError.status) &&
                !serverSuccess.status && (
                  <Spacing bottom={4}>
                    <Alert title={serverError.msg} danger full hideStatusIcon />
                  </Spacing>
                )}
              {serverSuccess.status && !serverError.status && (
                <Spacing bottom={4}>
                  <Alert title={serverSuccess.msg} notice full hideStatusIcon />
                </Spacing>
              )}
              <Spacing bottom={2}>
                <Input
                  name="email"
                  placeholder="Email"
                  label="Email"
                  large
                  type="email"
                  value={login.email}
                  onChange={updateField}
                  autoComplete="email"
                  noSpacing
                  invalid={error.email.status}
                />
              </Spacing>
              <Spacing bottom={2}>
                <Input
                  name="password"
                  placeholder="Password"
                  label="Password"
                  large
                  type="password"
                  value={login.password}
                  onChange={updateField}
                  autoComplete="password"
                  noSpacing
                  invalid={error.password.status}
                />
              </Spacing>
              <Spacing vertical={4}>
                <div
                  className={cx({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  })}
                >
                  <div>
                    <CheckBox
                      name="rememberme"
                      label="Remember me"
                      onChange={() => setRemember(!remember)}
                      checked={remember}
                    />
                  </div>
                  <div>
                    <Link small href="/forgot">
                      Forgot Password?
                    </Link>
                  </div>
                </div>
              </Spacing>
              <Spacing vertical={4}>
                <div className={cx(styles.signin_button)}>
                  <Button
                    onClick={handleSubmit}
                    loading={loading}
                    type="submit"
                    disabled={!login.email || !login.password}
                  >
                    <Spacing horizontal={4}> Sign in</Spacing>
                  </Button>
                </div>
              </Spacing>
            </form>
          </div>
        </div>
      </RightPanel>
      <div className={cx(styles.logo)}>
        <div
          className={cx(
            styles.logo_wrapper,
            startLogin && styles.logo_wrapper_show
          )}
        >
          <div
            className={cx(
              styles.logo_outer_circle,
              expand && styles.logo_outer_circle_expand,
              manufacturer && { backgroundColor: `#00B1A9` }
            )}
          >
            <div
              className={cx(
                styles.logo_inner_circle,
                expand && styles.logo_inner_circle_expand
              )}
            >
              <div
                className={cx(
                  styles.logo_company,
                  company && styles.logo_company_show
                )}
              >
                <IconObviousPlugin dark width={200} height={40} decorative />
              </div>
            </div>
          </div>
          <div
            className={cx(styles.logo_dot, expand && styles.logo_dot_hide)}
          ></div>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {};

Login.defaultProps = {};

export default Login;
