import React from "react";
import _pt from "prop-types";
import useStyles from "../../hooks/useStyles";

const styleSheet = ({ ui, unit, color }) => ({
  item_bordered: {
    borderTop: ui.border,

    ":last-child": {
      borderBottom: ui.border
    }
  },

  item_bordered_horizontal: {
    borderLeft: ui.border,

    ":last-child": {
      borderRight: ui.border
    }
  },

  item_compact: {
    paddingBottom: unit / 2,
    paddingTop: unit / 2
  },

  item_compact_horizontal: {
    paddingLeft: unit / 2,
    paddingRight: unit / 2
  },

  item_spacious: {
    paddingBottom: unit,
    paddingTop: unit
  },

  item_spacious_horizontal: {
    paddingLeft: unit,
    paddingRight: unit
  },

  item_decoration: {
    display: "flex",
    alignItems: "center",
    "::before": {
      content: '"▪"',
      color: color.core.primary[4],
      display: "inline-block",
      fontSize: "31px",
      verticalAlign: "middle",
      marginRight: "16px"
    }
  }
});

const ListItem = ({
  bordered,
  children,
  compact,
  horizontal,
  spacious,
  decoration
}) => {
  const [styles, cx] = useStyles(styleSheet);

  return (
    <li
      className={cx(
        !horizontal && bordered && styles.item_bordered,
        horizontal && bordered && styles.item_bordered_horizontal,
        !horizontal && compact && styles.item_compact,
        horizontal && compact && styles.item_compact_horizontal,
        !horizontal && spacious && styles.item_spacious,
        horizontal && spacious && styles.item_spacious_horizontal,
        decoration && styles.item_decoration
      )}
    >
      {children}
    </li>
  );
};

ListItem.propTypes = {
  horizontal: _pt.bool,
  children: _pt.any.isRequired,
  bordered: _pt.bool,
  compact: _pt.bool,
  spacious: _pt.bool
};

ListItem.defaultProps = {
  bordered: false,
  compact: false,
  horizontal: false,
  spacious: false
};

export default ListItem;
