import React from "react";
import useStyles from "../../../../core/src/hooks/useStyles";
import useTheme from "../../../../core/src/hooks/useTheme";
import Row from "../../../../core/src/components/Row";
import MenuToggle from "../../../../core/src/components/MenuToggle/index";
import MenuItem from "../../../../core/src/components/Menu/Item";
import IconMenuDots from "../../../../icons/src/interface/IconMenuDots";
import DateTime from "../../../../core/src/components/DateTime";
import Link from "../../../../core/src/components/Link";

const styleSheet = ({ color, unit }) => ({
  feature: {
    padding: unit * 1.5,
    backgroundColor: "#ffffff",
    borderRadius: unit,
    marginBottom: unit
  },
  options: {
    height: "100%",
    display: "flex",
    alignItems: "center"
  },
  options_items: {
    marginRight: unit * 4
  },
  options_items_max: {
    width: 200,
    display: "flex",
    justifyContent: "center",
    marginRight: unit * 4
  }
});

const Device = ({ children, device, product }) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();

  const handleHide = () => {};
  const handleShow = () => {};

  return (
    <div className={cx(styles.feature)}>
      <Row
        after={
          <div className={cx(styles.options)}>
            <div className={cx(styles.options_items_max)}>
              <Link
                href={`/product/groups/${
                  device.groupid ? device.groupid : "base"
                }`}
              >
                {device.groupname ? device.groupname : "Base"}
              </Link>
            </div>
            <div className={cx(styles.options_items_max)}>
              {<DateTime at={new Date(device.datecreated).getTime()} medium />}
            </div>
          </div>
        }
      >
        {children}
      </Row>
    </div>
  );
};

export default Device;
