import gql from "graphql-tag";

const typeDefs = gql`
  type User {
    firstName: String!
    email: String!
  }

  type Query {
    user: User!
  }

  type Mutation {
    updateUser(firstName: String!, email: String!): User!
  }
`;

export default typeDefs;
