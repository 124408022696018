import React from "react";
import _pt from "prop-types";
import { withRouter } from "react-router-dom";
import useStyles from "../../../core/src/hooks/useStyles";
import { useQuery } from "@apollo/react-hooks";
import { GET_GROUPS } from "./queries";
import PageHeader from "../../../components/PageHeader";
import GroupsList, { Group, BaseGroup } from "./GroupsList";
import Text from "../../../core/src/components/Text";
import LogoLoader from "../../../core/src/components/LogoLoader";
import Button from "../../../core/src/components/Button";
import Modal from "../../../core/src/components/Modal";
import GroupForm from "./GroupForm";
import { getRolePermissions } from "../../../helpers";
import Row from "../../../core/src/components/Row";
import Spacing from "../../../core/src/components/Spacing";

const styleSheet = ({ unit }) => ({
  options_bar: {
    display: "flex",
    paddingBottom: unit * 4,
    width: "100%",
    justifyContent: "flex-end"
  }
});

const Groups = ({ product }) => {
  const permissions = getRolePermissions("product");
  const [styles, cx] = useStyles(styleSheet);
  const [productID] = React.useState(product);
  const [visable, setVisable] = React.useState(false);
  const { loading, data } = useQuery(GET_GROUPS, {
    variables: { productid: productID },
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });

  return loading ? (
    <LogoLoader />
  ) : (
    <>
      <Spacing bottom={2}>
        <Row
          center
          before={<Text bold>{data.allGroups.edges.length + 1} Groups</Text>}
          after={
            <Button onClick={() => setVisable(true)}>Create new group</Button>
          }
        ></Row>
      </Spacing>

      <div>
        <GroupsList>
          <BaseGroup product={productID}>
            <div>
              <Text bold>Base Group</Text>
              <Text small>
                All devices will default to Base unless added to a custom
                management group
              </Text>
            </div>
          </BaseGroup>
          {data.allGroups.edges.map(({ node: group }) => {
            return (
              <Group key={group.id} group={group} product={productID}>
                <div>
                  <Text bold>{group.name}</Text>
                  <Text small>{group.description}</Text>
                </div>
              </Group>
            );
          })}
        </GroupsList>
      </div>
      {visable && (
        <Modal title={"Add Group"} onClose={() => setVisable(false)}>
          <GroupForm product={productID} onClose={() => setVisable(false)} />
        </Modal>
      )}
    </>
  );
};

Groups.propTypes = {};

Groups.defaultProps = {};

export default withRouter(Groups);
