import React from "react";
import _pt from "prop-types";
import uuid from "uuid/v4";
import throttle from "lodash/throttle";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  CREATE_GROUP_DEVICE,
  GET_DEVICES_NOT_IN_ANY_GROUP,
  GET_GROUP_DEVICES
} from "../../queries";
import Autocomplete from "../../../../../core/src/components/Autocomplete";
import FormActions from "../../../../../core/src/components/FormActions";

const Form = ({ onClose, group, product, ...props }) => {
  const [id] = React.useState(uuid());
  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [device, setDevice] = React.useState({
    memberid: ""
  });
  const [error, setError] = React.useState({
    memberid: { status: false }
  });

  const { loading: devicesLoading, data, refetch, networkStatus } = useQuery(
    GET_DEVICES_NOT_IN_ANY_GROUP,
    {
      variables: { groupid: group, productid: product, device: search },
      skip: !search,
      options: {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true
      }
    }
  );

  const [createDevice] = useMutation(CREATE_GROUP_DEVICE, {
    update(
      cache,
      {
        data: {
          createGroupMember: { groupMember }
        }
      }
    ) {
      const data = cache.readQuery({
        query: GET_GROUP_DEVICES,
        variables: { groupid: group, productid: product }
      });
      data.allGroupDevices.edges.push({
        node: {
          ...groupMember
        },
        __typename: "GroupMemberNodeEdge"
      });
      cache.writeQuery({
        query: GET_GROUP_DEVICES,
        variables: { groupid: group, productid: product },
        data
      });
    }
  });

  React.useEffect(() => {
    if (data && data.allDevicesNotInAnyGroup) {
      const items = throttle(createList, 300);
      setItems(items);
    }
  }, [data]);

  const createList = () => {
    const items = data.allDevicesNotInAnyGroup.edges.map(({ node }) => {
      return {
        name: node.serialnumber,
        value: node.serialnumber
      };
    });

    return items;
  };

  const handleChange = value => {
    setSearch(value);
  };

  const handleSubmit = data => {
    setLoading(true);

    return Promise.resolve(props.onSubmit(data, props)).finally(() => {
      setLoading(false);
    });
  };

  const validate = e => {
    e.preventDefault();
    if (!device.memberid) {
      setError({
        memberid: { status: true }
      });
      return;
    }

    handleCreate();
  };

  const handleCreate = () => {
    createDevice({
      variables: {
        memberid: device.memberid,
        productid: product,
        groupid: group
      },
      optimisticResponse: {
        __typename: "Mutation",
        createGroupMember: {
          groupMember: {
            id: -1,
            memberid: device.memberid,
            __typename: "GroupMemberNode"
          }
        }
      }
    }).catch(error => {
      setLoading(false);
    });
    onClose();
  };

  return (
    <form
      id={id}
      method="post"
      encType="multipart/form-data"
      onSubmit={handleSubmit}
    >
      <Autocomplete
        fetching={devicesLoading || !search}
        disableCache
        accessibilityLabel="Devices"
        label="Find a device to add"
        name="autocomplete"
        maxHeight={250}
        onChange={handleChange}
        onSelectItem={value => setDevice({ memberid: value })}
        onLoadItems={value =>
          Promise.resolve(
            items.filter(item => {
              return item.name.toLowerCase().match(value.toLowerCase());
            })
          )
        }
      />
      <FormActions
        continueText="Add device"
        processingText="Adding..."
        processing={loading}
        onCancel={() => onClose()}
        onContinue={validate}
      />
    </form>
  );
};

Form.propTypes = {};

Form.defaultProps = {};

export default Form;
