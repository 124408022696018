import gql from "graphql-tag";

const GET_DEVICES = gql`
  query allDevices(
    $productid: ID!
    $serialnumber: String
    $after: String
    $before: String
  ) {
    allDevices(
      productid: $productid
      first: 100
      after: $after
      before: $before
      serialnumber_Istartswith: $serialnumber
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          serialnumber
          datecreated
        }
      }
    }
  }
`;

const GET_NRF_DEVICES = gql`
  query allNrfcloudDevices($productid: ID!) {
    allNrfcloudDevices(productid: $productid) {
      edges {
        node {
          id
          serialnumber
          groupid
          groupname
          datecreated
        }
      }
    }
  }
`;

const IMPORT_DEVICES = gql`
  mutation importDevices {
    importDevices {
      devices
    }
  }
`;

export { GET_NRF_DEVICES, GET_DEVICES, IMPORT_DEVICES };
