import React from "react";
import { GET_PRODUCT, GET_PRODUCTS } from "./queries";
import { useQuery } from "@apollo/react-hooks";
import { Switch, Route, Redirect } from "react-router-dom";
import useStyles from "../../core/src/hooks/useStyles";
import Layout from "../../layout/src/components/Layout";
import LogoLoader from "../../core/src/components/LogoLoader/index";
import Content from "../../components/Content";
import Firmware from "./firmware";
import Devices from "./devices";
import Groups from "./groups";
import MainMenu from "../../components/MainMenu";
import TopMenu from "../../components/TopMenu";
import PageHeader from "../../components/PageHeader";
import Tabs, { Item } from "../../components/Tabs";
import Settings from "./settings";

const styleSheet = () => ({
  flex: {
    display: "flex"
  }
});

const Product = ({ match }) => {
  const [styles, cx] = useStyles(styleSheet);

  const { loading, data } = useQuery(GET_PRODUCT, {
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });

  return (
    <Layout
      noPadding
      noMaxwidth
      relative
      before={
        <div className={cx(styles.flex)}>
          <MainMenu />
        </div>
      }
    >
      <TopMenu />
      <Content>
        {loading ? (
          <LogoLoader />
        ) : (
          <>
            <PageHeader
              title="nRF Connect for Cloud"
              initials="nRF"
              tabs={
                <Tabs>
                  <Item name="Devices" to={`/product/devices`} />
                  <Item name="Groups" to={`/product/groups`} />
                  <Item name="Firmware" to={`/product/firmware`} />
                </Tabs>
              }
            />
            <Switch>
              <Redirect exact from={`/product`} to={`/product/devices`} />
              <Route
                exact
                path={`/product/groups`}
                render={() => <Groups product={data.nrfProduct.id} />}
              />
              <Route
                exact
                path={`/product/firmware`}
                render={() => <Firmware product={data.nrfProduct.id} />}
              />
              <Route
                exact
                path={`/product/devices`}
                render={() => <Devices product={data.nrfProduct.id} />}
              />
            </Switch>
          </>
        )}
      </Content>
    </Layout>
  );
};

export default Product;
