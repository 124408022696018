import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import _pt from "prop-types";
import { withRouter } from "react-router-dom";
import { GET_NRF_DEVICES, IMPORT_DEVICES } from "./queries";
import useStyles from "../../../core/src/hooks/useStyles";
import useTheme from "../../../core/src/hooks/useTheme";
import Text from "../../../core/src/components/Text";
import DevicesList, { Device } from "./DevicesList";
import IconSearch from "../../../icons/src/interface/IconSearch";
import Input from "../../../core/src/components/Input";
import AppLoader from "../../../core/src/components/AppLoader";
import Button from "../../../core/src/components/Button";
import { toLocalNumber, getRolePermissions } from "../../../helpers";
import Toast from "../../../core/src/components/Toast";
import Spacing from "../../../core/src/components/Spacing";
import Row from "../../../core/src/components/Row";
import Tooltip from "../../../core/src/components/Tooltip";

const styleSheet = ({ color, unit }) => ({
  search: {
    display: "flex",
    alignItems: "center",
    width: "100%"
  },
  search_icon: {
    marginRight: unit
  },
  more: {
    paddingTop: unit * 4,
    paddingBottom: unit * 4,
    display: "flex",
    justifyContent: "center"
  }
});
const Devices = ({ product }) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [productID] = React.useState(product);
  const [search, setSearch] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [refetching, setRefetching] = React.useState(false);
  const [importDevices] = useMutation(IMPORT_DEVICES);
  const [toast, setToast] = React.useState({
    success: false,
    msg: "",
    visable: false
  });

  const {
    loading: devicesLoading,
    data: devicesData,
    fetchMore,
    refetch
  } = useQuery(GET_NRF_DEVICES, {
    variables: { productid: productID, serialnumber: search }
  });

  const handleImport = () => {
    setRefetching(true);
    importDevices()
      .then(res => {
        console.log(res);
        if (res.data.importDevices.devices > 0) {
          setToast({
            success: true,
            msg: `${res.data.importDevices.devices} devices have been added`,
            visable: true
          });
          refetch()
            .then(() => {
              setRefetching(false);
            })
            .catch(() => {
              setRefetching(false);
            });
        } else {
          setToast({
            info: true,
            msg: `${res.data.importDevices.devices} devices have been added`,
            visable: true
          });
          setRefetching(false);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };

  const searchChange = value => {
    setSearch(value);
  };

  const handleClose = () => {
    setToast({
      msg: "",
      visable: false
    });
  };

  return (
    <>
      <div
        className={cx({
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          margin: 24,
          width: "100%",
          display: "flex",
          justifyContent: "center"
        })}
      >
        <div
          className={cx({
            width: 360,
            height: "auto",
            display: "flex",
            justifyContent: "center"
          })}
        >
          {toast.visable && (
            <Toast
              duration={5000}
              success={toast.success}
              message={toast.msg}
              onClose={handleClose}
            />
          )}
        </div>
      </div>
      <Spacing bottom={2}>
        <Row
          center
          after={
            <div className={cx({ display: "flex", alignContent: "center" })}>
              {/* <IconSearch
                className={cx(styles.search_icon)}
                accessibilityLabel="search"
                width={32}
                height={32}
                color={theme.color.core.neutral[3]}
              />
              <Input
                label="search"
                hideLabel
                autoComplete="off"
                name="search"
                value={search}
                onChange={searchChange}
                placeholder="find device"
                type="search"
              /> */}
              <div className={cx({ marginLeft: 16 })}>
                <Tooltip
                  content="Device list is auto-updated on every login"
                  above
                >
                  <Button disabled={refetching} onClick={handleImport}>
                    Fetch Devices
                  </Button>
                </Tooltip>
              </div>
            </div>
          }
        ></Row>
      </Spacing>
      <AppLoader
        fetched={!devicesLoading && !refetching}
        centered
        small
        loadingText={
          search
            ? `Fetching devices that start with ${search}`
            : "Fetching devices"
        }
        failureText="Failed to load devices"
      >
        <div
          className={cx({
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          })}
        ></div>

        <DevicesList product={productID}>
          {devicesData &&
            devicesData.allNrfcloudDevices &&
            devicesData.allNrfcloudDevices.edges.map(({ node: device }) => {
              return (
                <Device key={device.id} device={device} product={productID}>
                  <Text bold>{device.serialnumber}</Text>
                </Device>
              );
            })}
        </DevicesList>

        {devicesData &&
          devicesData.allDevices &&
          devicesData.allDevices.pageInfo.hasNextPage && (
            <div className={cx(styles.more)}>
              <Button
                loading={loading}
                onClick={() => {
                  setLoading(true);
                  fetchMore({
                    query: GET_NRF_DEVICES,
                    variables: {
                      productid: productID,
                      serialnumber: search,
                      after:
                        devicesData.allDevices &&
                        devicesData.allDevices.pageInfo.endCursor
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                      const newEdges =
                        fetchMoreResult.devicesData.allDevices.edges;
                      const pageInfo =
                        fetchMoreResult.devicesData.allDevices.pageInfo;
                      setLoading(false);
                      return newEdges.length
                        ? {
                            // Put the new comments at the end of the list and update `pageInfo`
                            // so we have the new `endCursor` and `hasNextPage` values
                            allDevices: {
                              __typename:
                                previousResult.devicesData.allDevices
                                  .__typename,
                              edges: [
                                ...previousResult.devicesData.allDevices.edges,
                                ...newEdges
                              ],
                              pageInfo
                            }
                          }
                        : previousResult;
                    }
                  });
                }}
              >
                Load More
              </Button>
            </div>
          )}
      </AppLoader>
    </>
  );
};

Devices.propTypes = {};

Devices.defaultProps = {};

export default withRouter(Devices);
