import React from "react";
import _pt from "prop-types";
import uuid from "uuid/v4";
import useStyles, { aesthetic } from "../../core/src/hooks/useStyles";
import useTheme from "../../core/src/hooks/useTheme";

const styleSheet = ({ color, unit }) => ({
  LeftPanel: {
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 0,
    left: 0,
    width: 57 * unit,
    height: " 100%",
    overflow: "hidden",
    background: "rgba(36, 40, 56, 0.65)",
    "@media": {
      "(min-height: 600px)": {
        position: "fixed"
      },
      "(min-width: 1024px)": {
        display: "flex"
      }
    }
  }
});

const LeftPanel = ({ children, className }) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [id] = React.useState(uuid());

  return <div className={cx(styles.LeftPanel, className)}>{children}</div>;
};

LeftPanel.propTypes = {};

LeftPanel.defaultProps = {};

export default LeftPanel;
