import React from "react";
import _pt from "prop-types";
import uuid from "uuid/v4";
import useStyles, { aesthetic } from "../../core/src/hooks/useStyles";
import useTheme from "../../core/src/hooks/useTheme";

const styleSheet = ({ color, unit }) => ({
  RightPanel: {
    position: "relative",
    flex: "1 1 auto",
    "@media": {
      "(min-width: 1024px)": {
        marginLeft: 57 * unit
      }
    }
  }
});

const RightPanel = ({ children, className }) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [id] = React.useState(uuid());

  return <div className={cx(styles.RightPanel, className)}>{children}</div>;
};

RightPanel.propTypes = {};

RightPanel.defaultProps = {};

export default RightPanel;
