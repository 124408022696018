import React from "react";
import _pt from "prop-types";
import uuid from "uuid/v4";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_GROUP, UPDATE_GROUP, GET_GROUPS } from "../queries";
import FormActions from "../../../../core/src/components/FormActions";
import TextArea from "../../../../core/src/components/TextArea";
import Input from "../../../../core/src/components/Input";
import Select from "../../../../core/src/components/Select";
import Switch from "../../../../core/src/components/Switch";

const GroupForm = ({ product, selectedGroup, onClose, ...props }) => {
  const [id] = React.useState(uuid());
  const [loading, setLoading] = React.useState(false);
  const [group, setGroup] = React.useState({
    name: "",
    description: "",
    priority: 0
  });

  const [error, setError] = React.useState({
    name: { status: false }
  });
  const [type, setType] = React.useState("create");

  const [createGroup] = useMutation(CREATE_GROUP, {
    update(
      cache,
      {
        data: {
          createGroup: { group }
        }
      }
    ) {
      const data = cache.readQuery({
        query: GET_GROUPS,
        variables: { productid: product }
      });
      data.allGroups.edges.push({
        node: { ...group },
        __typename: "GroupNodeEdge"
      });
      cache.writeQuery({
        query: GET_GROUPS,
        variables: { productid: product },
        data
      });
    }
  });

  const [updateGroup] = useMutation(UPDATE_GROUP);

  React.useEffect(() => {
    if (selectedGroup) {
      setGroup({ ...selectedGroup });
      setType("edit");
    }
  }, []);

  const handleSubmit = data => {
    setLoading(true);

    return Promise.resolve(props.onSubmit(data, props)).finally(() => {
      setLoading(false);
    });
  };

  const updateField = (value, event) => {
    setGroup({
      ...group,
      [event.target.name]: value
    });
  };

  const validate = e => {
    e.preventDefault();
    if (!group.name) {
      setError({
        name: { status: true }
      });
      return;
    }

    handleCreate();
  };

  const handleCreate = () => {
    setLoading(true);
    if (type === "create") {
      createGroup({
        variables: {
          productid: product,
          ...group
        },
        optimisticResponse: {
          __typename: "Mutation",
          createGroup: {
            group: {
              id: -1,
              ...group,
              __typename: "GroupNode"
            },
            __typename: "createGroup"
          }
        }
      }).catch(error => {
        setLoading(false);
      });
      onClose();
    }
    if (type === "edit") {
      updateGroup({
        variables: {
          ...group
        },
        optimisticResponse: {
          __typename: "Mutation",
          updateGroup: {
            group: {
              ...group,
              __typename: "GroupNode"
            },
            __typename: "updateGroup"
          }
        }
      }).catch(error => {
        setLoading(false);
      });
      onClose();
    }
  };
  return (
    <form
      id={id}
      method="post"
      encType="multipart/form-data"
      onSubmit={handleSubmit}
    >
      <Input
        name="name"
        label="Group Name"
        placeholder="Alpha"
        value={group.name}
        onChange={updateField}
        errorMessage="Group name is needed"
        invalid={error.name.status}
      />
      <TextArea
        name="description"
        label="Describe your group"
        placeholder="Testing firmware with alpha"
        value={group.description}
        onChange={updateField}
        optional
      />
      <FormActions
        continueText={`${type === "create" ? "Create" : "Update"} group`}
        processingText={`${type === "create" ? "Creating..." : "Updating.."}`}
        processing={loading}
        onCancel={() => onClose()}
        onContinue={validate}
        disabled={!group.name}
      />
    </form>
  );
};

GroupForm.propTypes = {};

GroupForm.defaultProps = {};

export default GroupForm;
