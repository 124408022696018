import React from "react";
import { Link } from "react-router-dom";
import _pt from "prop-types";
import useStyles from "../../core/src/hooks/useStyles";
import useTheme from "../../core/src/hooks/useTheme";
import Aside from "../../layout/src/components/Aside";
import Spacing from "../../core/src/components/Spacing";
import List from "../../core/src/components/List";
import IconObviousShortLogo from "../../icons/src/interface/IconObviousShortLogo";
import MenuItem from "./Item";
import IconWatch from "../../icons/src/general/IconWatch";
import IconWallet from "../../icons/src/general/IconWallet";
import IconUserGroup from "../../icons/src/general/IconUserGroup";
import ProfilePhoto from "../../core/src/components/ProfilePhoto";
import Dropdown from "../../core/src/components/Dropdown";
import Menu, { Item } from "../../core/src/components/Menu";
import { changeThemeColors, getRolePermissions } from "../../helpers";
import history from "../../history";
import IconArticle from "../../icons/src/general/IconArticle";
import IconExperiences from "../../icons/src/general/IconExperiences";
import IconChat from "../../icons/src/interface/IconChat";
import IconNrfCloud from "../../icons/src/general/IconNrfCloud";
import IconUpload from "../../icons/src/interface/IconUpload";

const styleSheet = () => ({
  main_menu: {
    display: "flex",
    flexDirection: "column",
    width: "72px",
    height: "100%"
  },
  menu: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  account: {
    height: 72,
    width: 72,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  account_item: {}
});

const MainMenu = ({ dark }) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();

  return (
    <Aside width={72} color={theme.color.core.neutral[9]} noPadding>
      <div className={cx(styles.main_menu)}>
        <Spacing top={4} bottom={4}>
          <div
            className={cx({
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            })}
          >
            <Link to="/product">
              <IconObviousShortLogo width="32" height="32" decorative />
            </Link>
          </div>
        </Spacing>
        <div className={cx(styles.menu)}>
          <Spacing vertical={0}>
            <List>
              <MenuItem
                icon={<IconWatch color={"#fff"} size={24} decorative />}
                name="Product"
                to={`/product`}
              />
              <MenuItem
                icon={<IconUserGroup color="#fff" size={24} decorative />}
                name="Team"
                to={`/team`}
              />
              <MenuItem
                external
                externalMsg="You are leaving The Obvious Plugin and returning to nRF Connect for Cloud"
                icon={<IconNrfCloud color={"#fff"} size={24} decorative />}
                name="nRF Cloud"
                linkText="Continue to nrfcloud.com"
                to={`https://nrfcloud.com/`}
              />
              <MenuItem
                icon={<IconArticle color="#fff" size={24} decorative />}
                name="Docs"
                to={`/docs`}
              />
              {/* <MenuItem
                icon={<IconUpload color="#fff" size={24} decorative />}
                name="Upgrade"
                to={`/upgrade`}
              /> */}
            </List>
          </Spacing>
        </div>
        <Spacing bottom={3}>
          <div className={cx(styles.account)}></div>
        </Spacing>
      </div>
    </Aside>
  );
};

MainMenu.propTypes = {
  children: _pt.node
};

MainMenu.defaultProps = {
  children: null
};

export default MainMenu;
