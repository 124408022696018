import React from "react";
import withIcon from "../withIcon";

const IconNordic = ({ ...props }) => {
  return (
    <svg {...props} viewBox="0 0 226 64">
      <g fill="#B8C2CC" fillRule="nonzero">
        <path
          d="M52.9.4s-.1 0-.1.1c-.2.1-.3.2-.5.2L36.8 9.6l18 10.4V0c-.6 0-1.3.2-1.9.4z"
          opacity="0.6"
        ></path>
        <path
          d="M21.2 63.1l16.4-9.5-18-10.4v20.3c.5.1 1-.1 1.6-.4z"
          opacity="0.8"
        ></path>
        <path
          d="M73.2 52.5L36.3 31.2l-1.7-1L1.3 10.9c-.2.6-.4 1.2-.4 1.9v38.6c0 1.2.7 2.3 1.7 2.9l15.2 8.9c.5.3 1.1.4 1.7.4V43.3L54 63.2c.1.1.2.1.3.1h.1c1 .4 1.8.1 2.7-.5l5.8-3.3 9.2-5.5c.5-.3.9-.9 1.1-1.5z"
          opacity="0.5"
        ></path>
        <path d="M73.4 27.3V12.8c0-1.9-1-3.6-2.6-4.5L57.4.6c-.8-.4-1.7-.6-2.6-.6v20.1L24.7 2.6 21.3.7c-1.5-.8-2.8-.9-4.3-.2 0 0-.1 0-.1.1-.2.1-.3.2-.5.3L11.1 4 3.5 8.4C2.5 9 1.7 9.9 1.2 11l33.3 19.2 1.7 1 36.9 21.3c.2-.7.2-1.9.2-1.9V27.3h.1z"></path>
        <g transform="translate(83 12)">
          <path d="M28.4 40.5L26.7 40.5 25.8 34.7 25.8 34.7 24.1 39.2 23 39.2 21.3 34.7 21.3 34.7 20.4 40.5 18.7 40.5 20.3 31.5 21.5 31.5 23.5 36.6 23.5 36.6 25.5 31.5 26.7 31.5z"></path>
          <path d="M21 25.1L16.9 25.1 5 8.5 5 8.5 5 25.2 0.6 25.2 0.6 0.9 4.7 0.9 16.5 17.6 16.5 17.6 16.5 0.9 20.9 0.9z"></path>
          <path d="M50.8 13c0 7.5-5.5 12.7-12.7 12.7-7.2 0-12.7-5.1-12.7-12.7C25.4 5.5 30.9.3 38.1.3 45.3.3 50.8 5.5 50.8 13zm-20.5 0c0 4.8 3.3 8.1 7.8 8.1 4.6 0 7.8-3.3 7.8-8.1s-3.3-8.1-7.8-8.1-7.8 3.3-7.8 8.1zM61.7 15.4H60v9.7h-4.4V.8h8.9c4.8 0 7.8 3 7.8 7.4 0 3.6-2.1 6.3-5.7 7l7.2 9.9h-5.6l-6.5-9.7zm-1.6-3.9h3.8c2.6 0 3.8-1.2 3.8-3.3 0-2-1.2-3.3-3.8-3.3h-3.8v6.6zM98.1 13c0 7.6-5.3 12.1-12.6 12.1h-7.8V.8h7.8C92.8.8 98.1 5.4 98.1 13zM82.2 4.9V21h3.5c4.4 0 7.6-2.8 7.6-8S90 5 85.7 5l-3.5-.1z"></path>
          <path d="M107.3 25.1L102.8 25.1 102.8 0.8 107.3 0.8z"></path>
          <path d="M112.1 13c0-7.7 5.4-12.7 13.1-12.7 3.7 0 6.5 1.1 8.7 2.7l-2.6 3.9c-1.5-1-3.5-2-6.1-2-4.7 0-8.2 3.2-8.2 8.1 0 5 3.5 8.1 8.2 8.1 2.6 0 4.6-1 6.1-2l2.6 3.9c-2.2 1.5-4.9 2.7-8.7 2.7-7.6-.1-13.1-5.1-13.1-12.7zM0 39.8l.7-1.5c.7.4 1.6.8 2.4.8.8 0 1.3-.4 1.3-.9s-.3-.8-.9-1.2L2 36C1 35.3.6 34.6.6 33.6c0-1.4 1-2.3 2.7-2.3.9 0 1.8.2 2.6.6l-.7 1.5c-.7-.3-1.3-.6-1.9-.6-.7 0-.9.3-.9.7 0 .3.2.6.7.9l1.6 1.1c1 .7 1.5 1.4 1.5 2.5 0 1.7-1.2 2.6-3.1 2.6-1.2.1-2.3-.2-3.1-.8"></path>
          <path d="M15.2 39L15.2 40.5 9.8 40.5 9.8 31.5 15.1 31.5 15.1 33 11.4 33 11.4 34.8 14.8 34.8 14.8 36.3 11.4 36.3 11.4 38.9 15.2 38.9z"></path>
          <path d="M33.7 40.5L32 40.5 32 31.5 33.7 31.5z"></path>
          <path d="M33.7 40.5L32 40.5 32 31.5 33.7 31.5z"></path>
          <path d="M37.1 36c0-2.8 2-4.7 4.9-4.7 1.4 0 2.4.4 3.2 1l-.9 1.4c-.6-.4-1.3-.7-2.3-.7-1.7 0-3 1.2-3 3s1.3 3 3 3c1 0 1.7-.4 2.3-.7l.9 1.4c-.8.6-1.8 1-3.2 1-2.9 0-4.9-1.8-4.9-4.7zM57.3 36c0 2.8-2 4.7-4.7 4.7s-4.7-1.9-4.7-4.7c0-2.8 2-4.7 4.7-4.7 2.6 0 4.7 1.9 4.7 4.7zm-7.6 0c0 1.8 1.2 3 2.9 3s2.9-1.2 2.9-3-1.2-3-2.9-3-2.9 1.2-2.9 3z"></path>
          <path d="M68.3 40.5L66.8 40.5 62.4 34.3 62.4 34.3 62.4 40.5 60.8 40.5 60.8 31.5 62.3 31.5 66.7 37.7 66.7 37.7 66.7 31.5 68.3 31.5z"></path>
          <path d="M79.9 36c0 2.8-2 4.5-4.7 4.5h-2.9v-9h2.9c2.7 0 4.7 1.7 4.7 4.5zM74 33v6h1.3c1.6 0 2.8-1 2.8-3 0-1.9-1.2-3-2.8-3H74zM90.7 36.9c0 2.3-1.3 3.7-3.7 3.7-2.4 0-3.7-1.5-3.7-3.7v-5.4H85v5.3c0 1.3.8 2.1 2 2.1s2-.8 2-2.1v-5.3h1.7v5.4zM94.1 36c0-2.8 2-4.7 4.9-4.7 1.4 0 2.4.4 3.2 1l-.9 1.4c-.6-.4-1.3-.7-2.3-.7-1.7 0-3 1.2-3 3s1.3 3 3 3c1 0 1.7-.4 2.3-.7l.9 1.4c-.8.6-1.8 1-3.2 1-2.8 0-4.9-1.9-4.9-4.7z"></path>
          <path d="M111.4 33L109 33 109 40.4 107.4 40.4 107.4 33 104.9 33 104.9 31.5 111.4 31.5z"></path>
          <path d="M123.4 36c0 2.8-2 4.7-4.7 4.7S114 38.8 114 36c0-2.8 2-4.7 4.7-4.7 2.6 0 4.7 1.9 4.7 4.7zm-7.6 0c0 1.8 1.2 3 2.9 3s2.9-1.2 2.9-3-1.2-3-2.9-3-2.9 1.2-2.9 3zM129 36.9h-.6v3.6h-1.6v-9h3.3c1.8 0 2.9 1.1 2.9 2.7 0 1.3-.8 2.3-2.1 2.6l2.7 3.7h-2.1l-2.5-3.6zm-.6-1.4h1.4c.9 0 1.4-.4 1.4-1.2 0-.7-.4-1.2-1.4-1.2h-1.4v2.4z"></path>
          <path
            d="M.9 1.8v1.3H.7V.3h1.2c.5 0 .9.2.9.7 0 .3-.1.6-.5.7.2.1.5.2.5.7v.7h-.3v-.7c0-.5-.2-.6-.7-.6H.9zm0-.2h.9c.5 0 .7-.2.7-.6 0-.3-.3-.5-.7-.5H.9v1.1z"
            transform="translate(137) translate(1 2)"
          ></path>
          <path
            d="M2.9 3.2h-.4v-.1-.7c0-.4-.1-.5-.7-.5H1v1.3H.6v-3h1.3c.6 0 1 .3 1 .8 0 .3-.1.5-.4.7.2.1.3.4.3.7v.1c0 .2 0 .5.1.7zM2.6 3h.1v-.6c0-.5-.2-.6-.4-.6L2 1.7l.3-.1c.3-.1.5-.3.5-.6 0-.6-.6-.7-.9-.7H.7V3h.1V1.7h1c.5 0 .8.1.8.7V3zm-.8-1.3h-1V.5h1c.7 0 .8.3.8.6 0 .4-.3.6-.8.6zM1 1.5h.8c.6 0 .7-.3.7-.5 0-.1 0-.4-.7-.4H1v.9z"
            transform="translate(137) translate(1 2)"
          ></path>
          <g>
            <path
              d="M2.7 6.3C1.2 6.3 0 5.1 0 3.6 0 2.1 1.2.9 2.7.9c1.5 0 2.7 1.2 2.7 2.7-.1 1.5-1.3 2.7-2.7 2.7zm0-5C1.4 1.3.3 2.4.3 3.7.3 5 1.4 6.1 2.7 6.1 4 6.1 5.1 5 5.1 3.7c0-1.4-1.1-2.4-2.4-2.4z"
              transform="translate(137)"
            ></path>
            <path
              d="M2.7 6.4C1.2 6.4 0 5.2 0 3.7 0 2.2 1.2 1 2.7 1c1.5 0 2.7 1.2 2.7 2.7 0 1.5-1.2 2.7-2.7 2.7zm0-5.3C1.3 1.1.1 2.3.1 3.7c0 1.4 1.2 2.6 2.6 2.6 1.4 0 2.6-1.2 2.6-2.6-.1-1.5-1.2-2.6-2.6-2.6zm0 5C1.3 6.1.2 5 .2 3.6s1.1-2.5 2.5-2.5 2.5 1.1 2.5 2.5S4 6.1 2.7 6.1zm0-4.8C1.4 1.3.4 2.3.4 3.6c0 1.3 1 2.3 2.3 2.3C4 5.9 5 4.9 5 3.6c0-1.3-1-2.3-2.3-2.3z"
              transform="translate(137)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default withIcon("IconNordic")(IconNordic);
