import React from "react";
import _pt from "prop-types";
import uuid from "uuid/v4";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  GET_FIRMWARE,
  CREATE_GROUP_FIRMWARE_ASSIGNMENT,
  UPDATE_GROUP_FIRMWARE_ASSIGNMENT,
  GET_FIRMWARE_ASSIGNMENT,
  GET_BASE_FIRMWARE_ASSIGNMENT
} from "../../queries";
import Select from "../../../../../core/src/components/Select";
import Input from "../../../../../core/src/components/Input";
import TextArea from "../../../../../core/src/components/TextArea";
import FormActions from "../../../../../forms/components/FormActions";
import {
  convertVersionToString,
  convertIntegerToVersion
} from "../../../../../helpers";

const Form = ({ onClose, product, group, selectedAssignment, ...props }) => {
  const [id] = React.useState(uuid());
  const [type, setType] = React.useState("create");
  const [loading, setLoading] = React.useState(false);
  const [assignment, setAssignment] = React.useState({
    firmwareid: "",
    minverandroid: 0,
    minverios: 0,
    comment: ""
  });

  const [error, setError] = React.useState({
    firmwareid: { status: false },
    minverandroid: { status: false },
    minverios: { status: false }
  });

  const { loading: firmwareLoading, data: firmwareData } = useQuery(
    GET_FIRMWARE,
    {
      variables: { productid: product },
      options: {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true
      }
    }
  );

  const [createAssignment] = useMutation(CREATE_GROUP_FIRMWARE_ASSIGNMENT, {
    update(
      cache,
      {
        data: {
          createGroupFirmwareAssignment: { assignment }
        }
      }
    ) {
      const data = cache.readQuery({
        query: GET_FIRMWARE_ASSIGNMENT,
        variables: {
          productid: product,
          groupid: group !== "base" ? group : null
        }
      });

      data.allFirmwareAssignments.edges.push({
        node: {
          ...assignment,
          firmware: {
            ...assignment.firmware,
            ...assignment.firmwareid
          }
        },
        __typename: "GroupFirmwareAssignmentNodeEdge"
      });

      data.allFirmwareAssignments.edges.sort(
        (a, b) => a.node.firmware.version > b.node.firmware.version
      );

      cache.writeQuery({
        query: GET_FIRMWARE_ASSIGNMENT,
        variables: {
          productid: product,
          groupid: group !== "base" ? group : null
        },
        data
      });
    }
  });

  const [updateAssignment] = useMutation(UPDATE_GROUP_FIRMWARE_ASSIGNMENT);

  React.useEffect(() => {
    if (selectedAssignment) {
      setAssignment({
        firmwareid: selectedAssignment.firmware.id,
        minverandroid: convertIntegerToVersion(
          selectedAssignment.minverandroid
        ),
        minverios: convertIntegerToVersion(selectedAssignment.minverios),
        comment: selectedAssignment.comment
      });
      setType("edit");
    }
  }, []);

  const updateField = (value, event) => {
    setAssignment({
      ...assignment,
      [event.target.name]: value
    });
    setError({
      ...error,
      [event.target.name]: { status: false }
    });
  };

  const validate = e => {
    e.preventDefault();
    let submitError = false;
    var errors = {};
    if (!assignment.firmwareid) {
      errors = {
        ...errors,
        firmwareid: { status: true }
      };
      submitError = true;
    }

    if (submitError) {
      setError({ ...error, ...errors });
      return;
    }

    handleCreate();
  };

  const handleCreate = () => {
    setLoading(true);
    const fw = firmwareData.allFirmware.edges.find(({ node }) => {
      return node === assignment.firmwareid;
    });
    const convertedAssignment = {
      productid: product,
      groupid: group !== "base" ? group : null,
      ...assignment,
      minverandroid: assignment.minverandroid,
      minverios: assignment.minverios
    };
    if (type === "create") {
      createAssignment({
        variables: {
          ...convertedAssignment
        },
        optimisticResponse: {
          __typename: "Mutation",
          createGroupFirmwareAssignment: {
            assignment: {
              id: -1,
              active: false,
              ...convertedAssignment,
              firmwareid: {
                ...fw,
                __typename: "FirmwareNode"
              },
              __typename: "GroupFirmwareAssignmentNode"
            },
            __typename: "CreateFirmwareAssignment"
          }
        }
      })
        .then(res => {
          onClose();
        })
        .catch(error => {
          setLoading(false);
        });
    }
    if (type === "edit") {
      updateAssignment({
        variables: {
          id: selectedAssignment.id,
          active: selectedAssignment.active,
          ...convertedAssignment
        },
        optimisticResponse: {
          __typename: "Mutation",
          updateGroupFirmwareAssignment: {
            assignment: {
              id: selectedAssignment.id,
              active: selectedAssignment.active,
              ...convertedAssignment,
              __typename: "GroupFirmwareAssignmentNode"
            },
            __typename: "updateGroupFirmwareAssignment"
          }
        }
      })
        .then(res => {
          onClose();
        })
        .catch(error => {
          setLoading(false);
        });
    }
  };

  const handleSubmit = data => {
    setLoading(true);

    return Promise.resolve(props.onSubmit(data, props)).finally(() => {
      setLoading(false);
    });
  };

  return (
    <form
      id={id}
      method="post"
      encType="multipart/form-data"
      onSubmit={handleSubmit}
    >
      <Select
        name="firmwareid"
        label="Select Firmware"
        placeholder={
          firmwareLoading ? "Fetching Firmware ..." : "Select Firmware Version"
        }
        errorMessage="Please select Firmware version"
        invalid={error.firmwareid.status}
        value={assignment.firmwareid}
        onChange={updateField}
        disabled={firmwareLoading}
      >
        {firmwareData && firmwareData.allFirmware ? (
          firmwareData.allFirmware.edges.map(({ node }) => {
            return (
              <option value={node.id} key={node.id}>
                {node.version}
              </option>
            );
          })
        ) : selectedAssignment ? (
          <option value={assignment.firmwareid} />
        ) : (
          <option />
        )}
      </Select>
      <FormActions
        continueText={`${type === "create" ? "Add" : "Update"} assignment`}
        processingText={`${type === "create" ? "Adding..." : "Updating..."}`}
        processing={loading}
        onCancel={() => onClose()}
        onContinue={validate}
      />
    </form>
  );
};

Form.propTypes = {};

Form.defaultProps = {};

export default Form;
