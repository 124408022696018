import React from "react";
import useStyles from "../../core/src/hooks/useStyles";
import Header from "../../components/Header";
import Title from "../../core/src/components/Title";
import Spacing from "../../core/src/components/Spacing";
import Text from "../../core/src/components/Text";
import AdaptiveGrid from "../../core/src/components/AdaptiveGrid/index";
import Content from "../../components/Content";
import IconButton from "../../core/src/components/IconButton";
import IconClose from "../../icons/src/interface/IconClose";
import history from "../../history";
import ResponsiveImage from "../../core/src/components/ResponsiveImage";
import Button from "../../core/src/components/Button";

const styleSheet = ({ unit }) => ({
  modal: {
    position: "fixed",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    zIndex: 3,
    visibility: "visible",
    opacity: 1,
    willChange: "opacity, visibility",
    transition: "visibility 0.2s ease 0s, opacity 0.2s ease 0s",
    background: "white"
  },
  modal_open: {
    overflowY: "auto",
    transform: "none",
    willChange: "transform",
    position: "fixed",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.2s cubic-bezier(0.2, 0.91, 0.85, 0.96) 0s"
  },
  model_presentation: {
    overflow: "unset",
    width: "100%",
    boxSizing: "border-box",
    position: "relative",
    textAlign: "center",
    maxHeight: "100vh",
    padding: `0 ${unit * 6}`
  }
});

const Doc = ({ match }) => {
  const [styles, cx] = useStyles(styleSheet);

  React.useEffect(() => {
    localStorage.setItem("seen-docs", true);
  }, []);

  const handleClose = () => {};

  return (
    <div className={cx(styles.modal)}>
      <Header
        logoDark
        actions={
          <IconButton>
            <IconClose
              accessibilityLabel="Close upgrade screen"
              size={32}
              onClick={() => history.push("/product")}
            />
          </IconButton>
        }
      />
      <div className={cx(styles.modal_open)}>
        <div className={cx(styles.model_presentation)}>
          <Content xsmall>
            <Spacing vertical={3}>
              <Title level={2}>How does the Obvious Plugin work?</Title>
              <Text leading="loose">
                The Obvious Plugin for nRF Connect for Cloud is a tool to manage
                your devices at scale, without needing to build your own
                infrastructure. We use Nordic’s Device API to achieve all
                functionality, so you don’t need to make any changes to your
                product to use the plugin. Our first release focuses on LTE
                firmware updates, specifically by deploying to many devices at
                once, and differentiating between different release populations.
              </Text>
              <Spacing top={5}>
                <ResponsiveImage src="/static/images/fetchdevices.gif" />
                <Spacing top={5}>
                  <Title level={3}>Fetch devices</Title>
                  <Spacing>
                    <Text leading="loose">
                      The Obvious Plugin automatically imports all LTE devices
                      from nRF Connect for Cloud on every login, as well as on
                      demand.
                    </Text>
                  </Spacing>
                </Spacing>
              </Spacing>
              <Spacing top={12}>
                <ResponsiveImage src="/static/images/groupdevices.gif" />
                <Spacing top={5}>
                  <Title level={3}>Group devices</Title>
                  <Spacing>
                    <Text leading="loose">
                      All fetched devices are automatically added to the “Base”
                      group, which is simply the default firmware release group.
                      If you would like to maintain more than one concurrent
                      release pathway, such as Alpha and Beta test group, you
                      can do so in the Groups section. When a device is assigned
                      to a group, it is removed from all other groups. If a
                      device is removed from any custom group, it will
                      automatically be added back to the Base group.
                    </Text>
                  </Spacing>
                </Spacing>
              </Spacing>
              <Spacing top={12}>
                <ResponsiveImage src="/static/images/uploadfirmware.gif" />
                <Spacing top={5}>
                  <Title level={3}>Upload firmware</Title>
                  <Spacing>
                    <Text leading="loose">
                      New firmware can be uploaded, and old firmware modified,
                      in the Firmware tab. When uploading a new file, you are
                      given the opportunity to set Min and Max versions, in the
                      event that the new firmware is in some way incompatible
                      with other releases. Firmware files are securely stored
                      here so that you can recall them to be re-released to
                      devices at any time simply by selecting them.
                    </Text>
                  </Spacing>
                </Spacing>
              </Spacing>
              <Spacing top={12}>
                <ResponsiveImage src="/static/images/updatefirmware.gif" />
                <Spacing top={5}>
                  <Title level={3}>Update firmware</Title>
                  <Spacing>
                    <Text leading="loose">
                      To perform a firmware update on a group, navigate to that
                      group, and if the correct firmware hasn’t yet been
                      assigned to that group, do so with the Assign button. Once
                      you’re ready to initiate the rollout, stage the desired
                      firmware, and click the Start button. Update progress and
                      historical logs can be viewed in the Jobs tab.
                    </Text>
                  </Spacing>
                </Spacing>
              </Spacing>
              <Spacing top={12}>
                <ResponsiveImage src="/static/images/team.gif" />
                <Spacing top={5}>
                  <Title level={3}>Team</Title>
                  <Spacing>
                    <Text leading="loose">
                      If you would like to invite others to manage firmware as
                      part of your team, you can do so in the Team tab. Please
                      note, only the user that originally created the Obvious
                      Plugin account can modify the API connection to nRF
                      Connect for Cloud.
                    </Text>
                  </Spacing>
                </Spacing>
              </Spacing>
              <Spacing top={12}>
                <Spacing top={5}>
                  <Button large href="/product">
                    Let's get started
                  </Button>
                </Spacing>
              </Spacing>
            </Spacing>
          </Content>
        </div>
      </div>
    </div>
  );
};

export default Doc;
