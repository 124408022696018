import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import useStyles from "../../core/src/hooks/useStyles";
import Layout from "../../layout/src/components/Layout";
import MainMenu from "../../components/MainMenu";
import Sidebar from "../../components/Sidebar";
import Spacing from "../../core/src/components/Spacing";
import IconDiode from "../../icons/src/interface/IconDiode";
import IconDashboard from "../../icons/src/interface/IconDashboard";
import Members from "./Members";
import Content from "../../components/Content";
import Invites from "./Invites";
import Title from "../../core/src/components/Title";
import { getRolePermissions } from "../../helpers";
import PageHeader from "../../components/PageHeader";
import Tabs, { Item } from "../../components/Tabs";
import Button from "../../core/src/components/Button";
import Modal from "../../core/src/components/Modal";
import InviteForm from "./Invites/InviteForm";
import TopMenu from "../../components/TopMenu";

const styleSheet = () => ({
  flex: {
    display: "flex"
  }
});

const Team = ({ match }) => {
  const [styles, cx] = useStyles(styleSheet);
  const [visable, setVisable] = React.useState(false);
  return (
    <Layout noPadding noMaxwidth relative before={<MainMenu />}>
      <TopMenu />
      <Content>
        <PageHeader
          title="Team"
          end={<Button onClick={() => setVisable(true)}>Invite</Button>}
        />
        <Members invite={visable} />
      </Content>
      {visable && (
        <Modal title={"Invite Member"} onClose={() => setVisable(false)}>
          <InviteForm onClose={() => setVisable(false)} />
        </Modal>
      )}
    </Layout>
  );
};

export default Team;
