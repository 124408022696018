import React from "react";
import useStyles from "../../../../core/src/hooks/useStyles";
import Text from "../../../../core/src/components/Text/index";
import Row from "../../../../core/src/components/Row";
import Firmware from "./Firmware";
import { getRolePermissions } from "../../../../helpers";
import Spacing from "../../../../core/src/components/Spacing";

const styleSheet = ({ color, unit }) => ({
  header: {
    paddingLeft: unit * 1.5,
    paddingRight: unit * 1.5,
    paddingBottom: unit * 1.5
  },
  header_children: {
    display: "flex",
    alignItems: "center"
  },
  header_items: {
    marginRight: unit * 4
  },
  header_items_max: {
    width: 100,
    display: "flex",
    justifyContent: "end",
    marginRight: unit * 4
  },
  options: {
    width: 24
  }
});

const FirmwareList = ({ children }) => {
  const permissions = getRolePermissions("product");
  const [styles, cx] = useStyles(styleSheet);

  return (
    <Spacing>
      <div className={cx(styles.header)}>
        <Row
          after={
            <div className={cx(styles.header_children)}>
              <div className={cx(styles.header_items_max)}>
                <Text small>Min Version</Text>
              </div>
              <div className={cx(styles.header_items_max)}>
                <Text small>Max Version</Text>
              </div>
              {(permissions.firmware.includes("UPDATE") ||
                permissions.firmware.includes("DELETE")) && (
                <div className={cx(styles.options)} />
              )}
            </div>
          }
        >
          <Text small>Version + Filename</Text>
        </Row>
      </div>
      {children}
    </Spacing>
  );
};

export { Firmware };
export default FirmwareList;
