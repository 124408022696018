import gql from "graphql-tag";

const AUTHENTICATION = gql`
  mutation UserLogin($email: String!, $password: String!) {
    tokenAuth(input: { email: $email, password: $password }) {
      token
      user {
        id
        email
        firstName
        lastName
      }
      userType {
        id
        name
      }
      manufacturer {
        id
        name
      }
      manufacturerLogo {
        value
      }
      manufacturerColor {
        value
      }
    }
  }
`;

const START_LOGIN = gql`
  mutation startLogin {
    startLogin(manufacturertype: "nRF") {
      success
    }
  }
`;

export { AUTHENTICATION, START_LOGIN };
