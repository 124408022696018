import gql from "graphql-tag";

const CREATE_MANUFACTURE_ACCOUNT = gql`
  mutation createManufacturer(
    $company: String!
    $firstName: String!
    $email: String!
    $password: String!
    $apikey: String!
  ) {
    createManufactuer(
      data: {
        company: $company
        firstName: $firstName
        email: $email
        password: $password
        apikey: $apikey
        manufacturertype: "nrf"
      }
    ) {
      success
    }
  }
`;

const AUTHENTICATION = gql`
  mutation UserLogin($email: String!, $password: String!) {
    tokenAuth(input: { email: $email, password: $password }) {
      token
      user {
        id
        email
        firstName
        lastName
      }
      userType {
        id
        name
      }
      manufacturer {
        id
        name
      }
      manufacturerLogo {
        value
      }
      manufacturerColor {
        value
      }
    }
  }
`;

const VERIFY_REGEISTER_USER_EMAIL = gql`
  mutation verifyRegisterUserEmail($email: String!) {
    verifyRegisterUserEmail(email: $email) {
      success
      errors
    }
  }
`;

const CREATE_ACCOUNT = gql`
  mutation registerUser(
    $email: String!
    $firstName: String!
    $password: String!
  ) {
    registerUser(
      data: { email: $email, firstName: $firstName, password: $password }
    ) {
      success
      errors
    }
  }
`;

const ADD_NRF_ACCOUNT = gql`
  mutation addNrfToPortalAccount($apikey: String!) {
    addNrfToPortalAccount(apikey: $apikey) {
      success
    }
  }
`;

export {
  CREATE_MANUFACTURE_ACCOUNT,
  AUTHENTICATION,
  CREATE_ACCOUNT,
  VERIFY_REGEISTER_USER_EMAIL,
  ADD_NRF_ACCOUNT
};
