import React from "react";
import _pt from "prop-types";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  GET_GROUP,
  GET_FIRMWARE_ASSIGNMENT,
  CREATE_JOB,
  GET_JOB,
  GET_JOBS
} from "../queries";
import { GET_PRODUCT } from "../../queries";
import useStyles from "../../../../core/src/hooks/useStyles";
import LogoLoader from "../../../../core/src/components/LogoLoader";
import Tabs, { Item } from "../../../../components/Tabs";
import Breadcrumbs, {
  Breadcrumb
} from "../../../../core/src/components/Breadcrumbs";
import GroupsFirmwareList, { Firmware } from "./GroupFirmwareList";
import Text from "../../../../core/src/components/Text";
import GroupsDeviceList from "./GroupDevicesList";
import Layout from "../../../../layout/src/components/Layout";
import Content from "../../../../components/Content";
import MainMenu from "../../../../components/MainMenu";
import Spacing from "../../../../core/src/components/Spacing";
import ProductBreadcrumb from "../../../../components/ProductBreadcrumb";
import Title from "../../../../core/src/components/Title";
import StatusLabel from "../../../../core/src/components/StatusLabel";
import GroupJobsList from "./GroupJobsList/index";
import Toast from "../../../../core/src/components/Toast";
import Button from "../../../../core/src/components/Button";
import TopMenu from "../../../../components/TopMenu";
import Loader from "../../../../core/src/components/Loader";
import Tooltip from "../../../../core/src/components/Tooltip";

const styleSheet = ({ color, unit }) => ({
  flex_center: {
    display: "flex",
    alignItems: "center"
  },
  tabs: {
    borderBottom: "solid 2px",
    borderColor: color.core.neutral[1]
  }
});

const GroupDetail = ({ match, product }) => {
  const [styles, cx] = useStyles(styleSheet);
  const [groupID] = React.useState(match.params.group);
  const [refetching, setRefetching] = React.useState(false);
  const [createJob] = useMutation(CREATE_JOB);
  const [active, setActive] = React.useState(false);
  const [toast, setToast] = React.useState({
    success: false,
    msg: "",
    visable: false
  });
  const { loading, data } = useQuery(GET_GROUP, {
    variables: { id: groupID },
    skip: groupID === "base",
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });
  const { loading: productLoading, data: productData } = useQuery(GET_PRODUCT, {
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });
  const { loading: firmwareLoading, data: assignmentData } = useQuery(
    GET_FIRMWARE_ASSIGNMENT,
    {
      variables: {
        groupid: groupID === "base" ? null : groupID,
        productid: productData && productData.nrfProduct.id
      },
      skip: productLoading,
      options: {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true
      }
    }
  );

  const { loading: jobLoading, data: jobData, refetch } = useQuery(GET_JOBS, {
    variables: {
      groupid: groupID !== "base" ? groupID : null,
      productid: productData && productData.nrfProduct.id
    },
    skip: productLoading,
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });

  React.useEffect(() => {
    if (assignmentData) {
      let active = false;
      assignmentData.allFirmwareAssignments.edges.map(
        ({ node: assignment }) => {
          if (assignment.active) {
            active = true;
          }
        }
      );
      setActive(active);
    }
  });

  const handleJob = () => {
    setRefetching(true);
    createJob({
      variables: {
        groupid: groupID !== "base" ? groupID : null,
        productid: product
      }
    })
      .then(res => {
        console.log("success", res);
        if (res.data.createJob) {
          setToast({
            success: true,
            msg: "Job created",
            visable: true
          });
          refetch()
            .then(() => {
              refetch();
              setRefetching(false);
            })
            .catch(() => {
              refetch();
              setRefetching(false);
            });
        } else {
          setToast({
            ...toast,
            msg: "Job not created",
            visable: true
          });
          setRefetching(false);
        }
      })
      .catch(error => {
        console.log("error", error);
        setRefetching(false);
      });
  };

  const handleClose = () => {
    setToast({
      msg: "",
      visable: false
    });
  };

  return (
    <Layout
      noPadding
      noMaxwidth
      relative
      before={
        <div className={cx(styles.flex)}>
          <MainMenu />
        </div>
      }
    >
      <TopMenu />
      <Content>
        {loading || firmwareLoading || productLoading ? (
          <LogoLoader />
        ) : (
          <>
            <div
              className={cx({
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                margin: 24,
                width: "100%",
                display: "flex",
                justifyContent: "center"
              })}
            >
              <div
                className={cx({
                  width: 360,
                  height: "auto",
                  display: "flex",
                  justifyContent: "center"
                })}
              >
                {toast.visable && (
                  <Toast
                    duration={5000}
                    success={toast.success}
                    message={toast.msg}
                    onClose={handleClose}
                  />
                )}
              </div>
            </div>
            <ProductBreadcrumb product={productData.nrfProduct.id}>
              <Breadcrumbs center accessibilityLabel="Breadcrumb">
                <Breadcrumb
                  highlighted
                  label="Groups"
                  href={`/product/groups`}
                />
                <Breadcrumb
                  hideIcon
                  label={
                    groupID === "base" ? "Base Group" : data && data.group.name
                  }
                />
              </Breadcrumbs>
            </ProductBreadcrumb>
            <Spacing bottom={2} top={4}>
              <div className={cx(styles.flex_center)}>
                <div className={cx({ flexGrow: 1 })}>
                  <div className={cx(styles.flex_center)}>
                    <Title level={2}>
                      {groupID === "base"
                        ? "Base Group"
                        : data && data.group.name}
                    </Title>
                  </div>

                  <Text truncated leading="none">
                    {groupID === "base"
                      ? "All devices will default to the base group if they are not in a group"
                      : data && data.group.description}
                  </Text>
                </div>
                <Button disabled={refetching || !active} onClick={handleJob}>
                  {refetching ? (
                    <Loader inline inverted />
                  ) : (
                    "Start firmware update"
                  )}
                </Button>
              </div>
            </Spacing>
            <Spacing bottom={4}>
              <div className={cx(styles.tabs)}>
                <Tabs>
                  <Item
                    name="Firmware"
                    to={`/product/groups/${groupID}/firmware`}
                  />
                  <Item
                    name="Devices"
                    to={`/product/groups/${groupID}/devices`}
                  />
                  <Item name="Jobs" to={`/product/groups/${groupID}/jobs`} />
                </Tabs>
              </div>
            </Spacing>

            <Switch>
              <Redirect
                exact
                from="/product/groups/:group"
                to="/product/groups/:group/firmware"
              />
              <Route
                path="/product/groups/:group/firmware"
                render={() => (
                  <GroupsFirmwareList
                    group={groupID}
                    product={productData.nrfProduct.id}
                  >
                    {assignmentData &&
                      assignmentData.allFirmwareAssignments &&
                      assignmentData.allFirmwareAssignments.edges.map(
                        ({ node: assignment }) => (
                          <Firmware
                            key={assignment.id}
                            assignment={assignment}
                            product={productData.nrfProduct.id}
                            group={groupID}
                          >
                            <Text bold>{assignment.firmware.version}</Text>
                            <Text small>{assignment.comment}</Text>
                          </Firmware>
                        )
                      )}
                  </GroupsFirmwareList>
                )}
              />
              <Route
                path="/product/groups/:group/devices"
                render={() => (
                  <GroupsDeviceList
                    group={groupID}
                    product={productData.nrfProduct.id}
                  />
                )}
              />
              <Route
                path="/product/groups/:group/jobs"
                render={() => (
                  <GroupJobsList
                    group={groupID}
                    product={productData.nrfProduct.id}
                    refetch
                  />
                )}
              />
            </Switch>
          </>
        )}
      </Content>
    </Layout>
  );
};

GroupDetail.propTypes = {};

GroupDetail.defaultProps = {};

export default withRouter(GroupDetail);
