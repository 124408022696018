import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { DESTROY_GROUP_DEVICE, GET_GROUP_DEVICES } from "../../queries";
import useStyles from "../../../../../core/src/hooks/useStyles";
import useTheme from "../../../../../core/src/hooks/useTheme";
import Row from "../../../../../core/src/components/Row";
import MenuToggle from "../../../../../core/src/components/MenuToggle";
import MenuItem from "../../../../../core/src/components/Menu/Item";
import IconMenuDots from "../../../../../icons/src/interface/IconMenuDots";
import { getRolePermissions } from "../../../../../helpers";

const styleSheet = ({ color, unit }) => ({
  feature: {
    padding: unit * 1.5,
    backgroundColor: "#ffffff",
    borderRadius: unit,
    marginBottom: unit
  },
  options: {
    display: "flex",
    alignItems: "center",
    height: "100%"
  },
  options_items: {
    marginRight: unit * 4
  }
});

const Device = ({ children, device, product, group }) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [destroy] = useMutation(DESTROY_GROUP_DEVICE, {
    update(cache, { data: { destroyGroup } }) {
      const data = cache.readQuery({
        query: GET_GROUP_DEVICES,
        variables: { groupid: group, productid: product }
      });
      data.allGroupDevices.edges = data.allGroupDevices.edges.filter(
        edge => edge.node.id !== device.id
      );
      cache.writeQuery({
        query: GET_GROUP_DEVICES,
        variables: { groupid: group, productid: product },
        data
      });
    }
  });

  const removeDevice = () => {
    destroy({
      variables: { id: device.id, productid: product, groupid: group },
      optimisticResponse: {
        __typename: " Mutation",
        destroyGroupMember: {
          id: device.id,
          __typname: "GroupMemberNode"
        }
      }
    });
  };

  const handleHide = () => {};
  const handleShow = () => {};

  return (
    <div className={cx(styles.feature)}>
      <Row center>
        <div className={cx({ flexGrow: 1 })}> {children}</div>
        {group !== "base" && (
          <div className={cx(styles.options)}>
            <MenuToggle
              closeOnClick
              accessibilityLabel="Actions"
              toggleIcon={
                <IconMenuDots decorative color={theme.color.core.neutral[4]} />
              }
              toggleLabel="Actions"
              zIndex={10}
              onShow={handleShow}
              onHide={handleHide}
            >
              <MenuItem onClick={removeDevice}>
                Remove {device.memberid}
              </MenuItem>
            </MenuToggle>
          </div>
        )}
      </Row>
    </div>
  );
};

export default Device;
