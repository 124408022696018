import React from "react";
import withIcon from "../withIcon";

const IconNrfCloud = ({ ...props }) => {
  return (
    <svg {...props} viewBox="0 0 121 84">
      <path
        d="M16.4 39.8c-.7.3-1.5.4-2.4.3h-.2c-.2 0-.3-.1-.5-.1-.1 0-.2 0-.3-.1-.2-.1-.4-.1-.6-.2 0 0-.1 0-.1-.1-.3-.1-.5-.2-.8-.4-.3-.1-.5-.3-.7-.5l-.1-.1c-.2-.1-.3-.3-.5-.4L10 38c-.1-.1-.2-.3-.4-.4l-.1-.1c-.6-.7-.9-1.5-1-2.3-.3-1.7-.1-3.5 4.1-11.1 4.2-7.5 5.6-8.6 7.2-9.3.7-.3 1.5-.4 2.4-.3h.1c.2 0 .4.1.6.1.1 0 .2 0 .3.1.2.1.4.1.6.2.1 0 .1 0 .2.1.3.1.5.2.8.4.3.2.5.3.8.5l.1.1c.2.1.3.3.5.4l.2.2c.2.2.3.3.4.5l.1.1c.5.7.9 1.5 1 2.2.1.6.1 1.2 0 2-.1.7.2 1.3.8 1.7 1.5.8 3.4 1.9 4.9 2.7 1 .5 2.2-.1 2.4-1.2.9-6.6-2.3-11.8-7.8-14.9-4.3-2.4-7.9-2.7-11.8-1.6-5.6 1.6-8 6-11.3 11.9C1.8 25.9-.6 30.3 1 35.9c1.1 3.9 3.3 6.8 7.6 9.2 5.5 3.1 11.5 3 16.6-1.1.9-.7.8-2.1-.2-2.7-1.5-.8-3.4-1.9-4.8-2.7-.6-.3-1.3-.3-1.8.2-1 .6-1.5.8-2 1z"
        transform="translate(-851 -144) translate(851.3 144.5) translate(32.7 21.5)"
      ></path>
      <path
        d="M37 7.9c.7-.3 1.5-.4 2.4-.3h.2c.2 0 .3.1.5.1.1 0 .2 0 .3.1.2.1.4.1.6.2 0 0 .1 0 .1.1.3.1.5.2.8.4.3.1.5.3.7.5l.1.1c.2.1.3.3.5.4l.2.2c.1.1.2.3.4.4l.1.1c.6.7.9 1.5 1 2.3.3 1.7.1 3.5-4.1 11.1-4.2 7.5-5.6 8.6-7.2 9.3-.7.3-1.5.4-2.4.3h-.1c-.2 0-.4-.1-.6-.1-.1 0-.2 0-.3-.1-.2-.1-.4-.1-.6-.2-.1 0-.1 0-.2-.1-.3-.1-.5-.2-.8-.4-.3-.2-.5-.3-.8-.5l-.1-.1c-.2-.1-.3-.3-.5-.4l-.2-.2c-.2-.2-.3-.3-.4-.5l-.1-.1c-.5-.7-.9-1.5-1-2.2-.1-.6-.1-1.2 0-2 .1-.7-.2-1.4-.8-1.7-1.5-.8-3.4-1.9-4.8-2.7-1-.5-2.2.1-2.4 1.2-.9 6.6 2.3 11.8 7.8 14.8 4.3 2.4 7.9 2.7 11.8 1.6 5.6-1.6 8-6 11.3-11.9 3.3-5.9 5.7-10.3 4.1-15.9-1.1-3.9-3.3-6.8-7.6-9.2-5.5-3.1-11.5-3-16.6 1.1-.9.7-.8 2.1.2 2.7 1.5.8 3.4 1.9 4.8 2.7.6.3 1.3.3 1.8-.2.9-.5 1.4-.7 1.9-.9z"
        transform="translate(-851 -144) translate(851.3 144.5) translate(32.7 21.5)"
      ></path>
      <path
        d="M95.1 83H27.8C12.2 82.3 0 68.9 0 52.5 0 35.7 12.9 22 28.8 22h1.3C36.9 8.4 50 0 64.6 0c17.7 0 33 12.5 37.7 30.5 10.8 3.3 18.2 13.7 18.2 25.7C120.4 71 109.1 83 95.1 83zm-67.2-5.7h67.2c10.8 0 19.6-9.5 19.6-21.1 0-9.9-6.2-18.3-15.1-20.5-1.3-.3-2.4-1.4-2.7-2.7-3.7-16.1-17-27.3-32.4-27.3-12.8 0-24.3 7.6-29.9 19.9-.6 1.4-2.1 2.3-3.7 2.1-.7-.1-1.4-.1-2.2-.1-12.8 0-23.1 11.2-23.1 24.9.1 13.3 9.8 24.2 22.3 24.8z"
        transform="translate(-851 -144) translate(851.3 144.5)"
      ></path>
    </svg>
  );
};

export default withIcon("IconNrfCloud")(IconNrfCloud);
