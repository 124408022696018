import React from "react";
import _pt from "prop-types";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  GET_MANUFACTURER_CONFIGS,
  UPDATE_MANUFACTURER_CONFIGS
} from "../queries";
import useStyles from "../../../../core/src/hooks/useStyles";
import Input from "../../../../core/src/components/Input";
import Row from "../../../../core/src/components/Row";
import Text from "../../../../core/src/components/Text";
import Spacing from "../../../../core/src/components/Spacing";
import Button from "../../../../core/src/components/Button";
import LogoLoader from "../../../../core/src/components/LogoLoader/index";
import FormActions from "../../../../core/src/components/FormActions";
import Alert from "../../../../core/src/components/Alert";
import Modal from "../../../../core/src/components/Modal";

const styleSheet = ({ color, unit }) => ({
  row_wrapper: {
    padding: unit * 1.5,
    backgroundColor: "#ffffff",
    borderRadius: unit,
    marginBottom: unit
  },
  buttons: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});

const Key = ({ product }) => {
  const [styles, cx] = useStyles(styleSheet);
  const [apiKey, setApiKey] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [confirmation, setConfirmation] = React.useState(false);
  const [updateManufacturerConfigs] = useMutation(UPDATE_MANUFACTURER_CONFIGS);
  const [alert, setAlert] = React.useState({
    status: false,
    msg: "",
    type: "",
    success: false
  });
  const { loading: configLoading, data: configData } = useQuery(
    GET_MANUFACTURER_CONFIGS,
    {
      options: {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true
      }
    }
  );

  React.useEffect(() => {
    if (configData) {
      setApiKey(configData.allManufacturerConfigs.edges[0].node.apikey);
    }
  }, [configData]);

  const handleSubmit = e => {
    e.preventDefault();
    setConfirmation(true);
  };

  const updateAPIKey = () => {
    setConfirmation(false);
    setLoading(true);
    updateManufacturerConfigs({
      variables: {
        id: configData.allManufacturerConfigs.edges[0].node.id,
        value: apiKey
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateManufacturerConfigs: {
          id: configData.allManufacturerConfigs.edges[0].node.id,
          value: apiKey,
          __typename: "ManufacturerIntegrationConfig"
        },
        __typename: "updateManufacturerConfigs"
      }
    })
      .then(res => {
        console.log(res);
        setLoading(false);
      })
      .catch(error => {
        setAlert({
          status: true,
          type: "danger",
          msg: error.graphQLErrors[0].message
        });
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setConfirmation(false);
    setApiKey(
      configData && configData.allManufacturerConfigs.edges[0].node.apikey
    );
  };

  return configLoading ? (
    <LogoLoader />
  ) : (
    <>
      <Spacing bottom={4}>
        <Alert
          warning
          title="Changing your nRF Connect for Cloud API key could leave associations to devices that no longer exist."
        />
      </Spacing>
      <form>
        {alert.status && (
          <Spacing bottom={2}>
            <Alert
              danger={alert.type === "danger"}
              success={alert.type === "success"}
              title={alert.msg}
              full
              hideStatusIcon
            />
          </Spacing>
        )}
        <Input
          label="nRF Connect for Cloud API Key"
          type="text"
          value={apiKey}
          onChange={value => setApiKey(value)}
        />
        <Spacing top={2}>
          <div className={cx(styles.buttons)}>
            <FormActions
              continueText="Update Key"
              processingText="Updating.."
              processing={loading}
              onContinue={handleSubmit}
              onCancel={handleCancel}
              disabled={
                !alert.status &&
                configData &&
                configData.allManufacturerConfigs.edges[0].node.apikey ===
                  apiKey
              }
              hideCancel={
                configData &&
                configData.allManufacturerConfigs.edges[0].node.apikey ===
                  apiKey
              }
            />
          </div>
        </Spacing>
      </form>
      {confirmation && (
        <Modal title={`Are you sure?`} onClose={handleCancel}>
          <FormActions
            continueText={`Yes`}
            processingText="Updating..."
            processing={loading}
            onCancel={handleCancel}
            onContinue={updateAPIKey}
          />
        </Modal>
      )}
    </>
  );
};

Key.propTypes = {};

Key.defaultProps = {};

export default Key;
