import React from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  UPDATE_GROUP_FIRMWARE_ASSIGNMENT,
  DESTROY_GROUP_FIRMWARE_ASSIGNMENT,
  GET_FIRMWARE_ASSIGNMENT,
  CANCEL_JOB
} from "../../queries";
import useStyles from "../../../../../core/src/hooks/useStyles";
import useTheme from "../../../../../core/src/hooks/useTheme";
import Row from "../../../../../core/src/components/Row";
import MenuToggle from "../../../../../core/src/components/MenuToggle";
import MenuItem from "../../../../../core/src/components/Menu/Item";
import IconMenuDots from "../../../../../icons/src/interface/IconMenuDots";
import Modal from "../../../../../core/src/components/Modal";

import { getRolePermissions } from "../../../../../helpers";
import DateTime from "../../../../../core/src/components/DateTime";
import Toast from "../../../../../core/src/components/Toast";
import Loader from "../../../../../core/src/components/Loader";
import Spacing from "../../../../../core/src/components/Spacing";

const styleSheet = ({ color, unit }) => ({
  feature: {
    padding: unit * 1.5,
    backgroundColor: "#ffffff",
    borderRadius: unit,
    marginBottom: unit
  },
  options: {
    display: "flex",
    alignItems: "center",
    height: "100%"
  },
  options_items: {
    marginRight: unit * 4,
    marginLeft: unit * 4
  },
  options_items_short: {
    marginRight: unit
  }
});

const Firmware = ({ children, assignment, product, group, job }) => {
  const total = (
    (100 * job.successcount) /
    (job.successcount + job.pendingcount + job.failedcount)
  ).toFixed();
  const permissions = getRolePermissions("product");
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [loading, setLoading] = React.useState(false);
  const [toast, setToast] = React.useState({
    success: false,
    msg: "",
    visable: false
  });
  const [cancelJob] = useMutation(CANCEL_JOB);

  const cancleJob = () => {
    setLoading(true);
    cancelJob({
      variables: {
        jobid: job.id
      },
      optimisticResponse: {
        __typename: "Mutation",
        cancelJob: {
          ManufacturerIntegrationDfuJob: {
            ...job,
            status: "CANCELED",
            __typename: "ManufacturerIntegrationDfuJob"
          },
          __typename: "cancelJob"
        }
      }
    }).then(res => {
      if (res.data.cancelJob.success === true) {
        setToast({
          success: true,
          msg: "Job cancled",
          visable: true
        });
        setLoading(false);
      } else {
        setToast({
          ...toast,
          msg: "Unable to cancel job",
          visable: true
        });
        setLoading(false);
      }
    });
  };
  const handleHide = () => {};
  const handleShow = () => {};

  return (
    <div className={cx(styles.feature)}>
      <div
        className={cx({
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          margin: 24,
          width: "100%",
          display: "flex",
          justifyContent: "center"
        })}
      >
        <div
          className={cx({
            width: 360,
            height: "auto",
            display: "flex",
            justifyContent: "center"
          })}
        >
          {toast.visable && (
            <Toast
              duration={5000}
              success={toast.success}
              message={toast.msg}
            />
          )}
        </div>
      </div>
      <Row
        after={
          <div className={cx(styles.options)}>
            <div className={cx(styles.options_items_short)}></div>
            <div className={cx(styles.options_items_short)}>
              {job.status !== "CANCELED" &&
                `${total !== "NaN" ? total : 0}% of device updated`}
            </div>
            <div className={cx(styles.options_items)}>
              <DateTime at={new Date(job.datecreated).getTime()} medium />
            </div>
            {loading && <Loader small inline />}
            {job.status === "IN_PROGRESS" && !loading ? (
              <MenuToggle
                closeOnClick
                accessibilityLabel="Actions"
                toggleIcon={
                  <IconMenuDots
                    decorative
                    color={theme.color.core.neutral[4]}
                  />
                }
                toggleLabel="Actions"
                zIndex={10}
                onShow={handleShow}
                onHide={handleHide}
              >
                <MenuItem danger onClick={cancleJob}>
                  Cancel
                </MenuItem>
              </MenuToggle>
            ) : (
              <Spacing right={3}> </Spacing>
            )}
          </div>
        }
      >
        {children}
      </Row>
    </div>
  );
};

export default Firmware;
