import gql from "graphql-tag";

const GET_USER = gql`
  {
    user @client {
      firstName
      email
    }
  }
`;

const resolvers = {
  Query: {
    user: (_, args, { cache }) => {
      const { user } = cache.readQuery({ query: GET_USER });
      return user;
    }
  },
  Mutation: {
    updateUser: (_, { firstName, email }, { cache }) => {
      const user = {
        firstName: firstName,
        email: email,
        __typename: "User"
      };
      cache.writeData({ data: { user } });

      return user;
    }
  }
};

export default resolvers;
