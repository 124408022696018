import React from "react";
import _pt from "prop-types";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  GET_MANUFACTURER_CONFIGS,
  UPDATE_MANUFACTURER_CONFIGS
} from "./queries";
import useStyles from "../../../core/src/hooks/useStyles";
import Input from "../../../core/src/components/Input";
import Row from "../../../core/src/components/Row";
import Text from "../../../core/src/components/Text";
import Spacing from "../../../core/src/components/Spacing";
import Button from "../../../core/src/components/Button";
import LogoLoader from "../../../core/src/components/LogoLoader/index";
import FormActions from "../../../core/src/components/FormActions";

const styleSheet = ({ color, unit }) => ({
  row_wrapper: {
    padding: unit * 1.5,
    backgroundColor: "#ffffff",
    borderRadius: unit,
    marginBottom: unit
  },
  options: {
    height: "100%",
    display: "flex",
    alignItems: "center"
  },
  options_items: {
    marginRight: unit * 2
  }
});

const Settings = ({ product }) => {
  const [styles, cx] = useStyles(styleSheet);
  const [apiKey, setApiKey] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [updateManufacturerConfigs] = useMutation(UPDATE_MANUFACTURER_CONFIGS);

  const { loading: configLoading, data: configData } = useQuery(
    GET_MANUFACTURER_CONFIGS,
    {
      options: {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true
      }
    }
  );

  React.useEffect(() => {
    if (configData) {
      setApiKey(configData.allManufacturerConfigs.edges[0].node.apikey);
    }
  }, [configData]);

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    updateManufacturerConfigs({
      variables: {
        id: configData.allManufacturerConfigs.edges[0].node.id,
        value: apiKey
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateManufacturerConfigs: {
          id: configData.allManufacturerConfigs.edges[0].node.id,
          value: apiKey,
          __typename: "ManufacturerIntegrationConfig"
        },
        __typename: "updateManufacturerConfigs"
      }
    })
      .then(res => {
        console.log(res);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };

  return configLoading ? (
    <LogoLoader />
  ) : (
    <div className={cx(styles.row_wrapper)}>
      <Input
        label="nRF Connect for Cloud API Key"
        type="text"
        value={apiKey}
        onChange={value => setApiKey(value)}
      />
      <Spacing top={2}>
        <FormActions
          continueText="Update Settings"
          processingText="Updating.."
          processing={loading}
          onContinue={handleSubmit}
          disabled={
            configData &&
            configData.allManufacturerConfigs.edges[0].node.apikey === apiKey
          }
          hideCancel
        />
      </Spacing>
    </div>
  );
};

Settings.propTypes = {};

Settings.defaultProps = {};

export default Settings;
