import React from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { DESTROY_GROUP, GET_GROUPS } from "../queries";
import useStyles from "../../../../core/src/hooks/useStyles";
import useTheme from "../../../../core/src/hooks/useTheme";
import Row from "../../../../core/src/components/Row";
import MenuToggle from "../../../../core/src/components/MenuToggle/index";
import MenuItem from "../../../../core/src/components/Menu/Item";
import IconMenuDots from "../../../../icons/src/interface/IconMenuDots";
import ProfilePhoto from "../../../../core/src/components/ProfilePhoto";
import Modal from "../../../../core/src/components/Modal";
import FormActions from "../../../../core/src/components/FormActions";
import GroupForm from "../GroupForm";
import { getRolePermissions } from "../../../../helpers";

const styleSheet = ({ color, unit }) => ({
  group: {
    padding: unit * 1.5,
    backgroundColor: "#ffffff",
    borderRadius: unit,
    marginBottom: unit
  },
  options: {
    display: "flex",
    alignItems: "center"
  },
  options_items: {
    marginRight: unit * 4
  }
});

const Group = ({ children, group, product }) => {
  const permissions = getRolePermissions("product");
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [destroyVisable, setDestroyVisable] = React.useState(false);
  const [editVisable, setEditVisable] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [destroy] = useMutation(DESTROY_GROUP, {
    update(cache, { data: { destroyGroup } }) {
      const data = cache.readQuery({
        query: GET_GROUPS,
        variables: { productid: product }
      });
      data.allGroups.edges = data.allGroups.edges.filter(
        edge => edge.node.id !== group.id
      );
      cache.writeQuery({
        query: GET_GROUPS,
        variables: { productid: product },
        data
      });
    }
  });

  const destroyGroup = () => {
    if (permissions.groups.includes("DELETE")) {
      setLoading(true);
      destroy({
        variables: {
          productid: product,
          id: group.id
        },
        optimisticResponse: {
          __typename: "Mutation",
          destroyGroup: {
            group: {
              id: group.id,
              __typename: "GroupNode"
            },
            __typename: "destroyGroup"
          }
        }
      });
      setDestroyVisable(false);
    }
  };

  const handleHide = () => {};
  const handleShow = () => {};

  return (
    <div>
      <div className={cx(styles.group)}>
        <Row
          center
          after={
            <div className={cx(styles.options)}>
              <MenuToggle
                closeOnClick
                accessibilityLabel="Actions"
                toggleIcon={
                  <IconMenuDots
                    decorative
                    color={theme.color.core.neutral[4]}
                  />
                }
                toggleLabel="Actions"
                zIndex={10}
                onShow={handleShow}
                onHide={handleHide}
              >
                <MenuItem href={`/product/groups/${group.id}`}>View</MenuItem>
                <MenuItem onClick={() => setEditVisable(true)}>Edit</MenuItem>
                <MenuItem danger onClick={() => setDestroyVisable(true)}>
                  Delete {group.name}
                </MenuItem>
              </MenuToggle>
            </div>
          }
        >
          <div className={cx({ flexGrow: 1 })}>
            <Link to={`/product/groups/${group.id}`}>{children}</Link>
          </div>
        </Row>
      </div>
      {destroyVisable && (
        <Modal
          title={`Delete ${group.name}`}
          subtitle={"Warning, this step cannot be undone"}
          onClose={() => setDestroyVisable(false)}
        >
          <FormActions
            continueText={`Yes, remove ${group.name}`}
            processingText="Removing..."
            processing={loading}
            onCancel={() => setDestroyVisable(false)}
            onContinue={destroyGroup}
          />
        </Modal>
      )}
      {editVisable && (
        <Modal
          title={`Edit ${group.name}`}
          onClose={() => setEditVisable(false)}
        >
          <GroupForm
            product={product}
            selectedGroup={group}
            onClose={() => setEditVisable(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default Group;
