import React from "react";
import useStyles from "../../../core/src/hooks/useStyles";
import Header from "../../../components/Header";
import Title from "../../../core/src/components/Title";
import Spacing from "../../../core/src/components/Spacing";
import Text from "../../../core/src/components/Text";
import AdaptiveGrid from "../../../core/src/components/AdaptiveGrid/index";
import Content from "../../../components/Content";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { ADD_NRF_ACCOUNT } from "../queries";
import Button from "../../../core/src/components/Button";
import Input from "../../../core/src/components/Input";
import Link from "../../../core/src/components/Link";
import Alert from "../../../core/src/components/Alert";
import history from "../../../history";

const styleSheet = ({ unit }) => ({
  modal: {
    position: "fixed",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    zIndex: 3,
    visibility: "visible",
    opacity: 1,
    willChange: "opacity, visibility",
    transition: "visibility 0.2s ease 0s, opacity 0.2s ease 0s",
    background: "white"
  },
  modal_open: {
    overflowY: "auto",
    transform: "none",
    willChange: "transform",
    position: "fixed",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.2s cubic-bezier(0.2, 0.91, 0.85, 0.96) 0s"
  },
  model_presentation: {
    overflow: "unset",
    width: "100%",
    boxSizing: "border-box",
    position: "relative",
    textAlign: "center",
    maxHeight: "100vh",
    padding: `0 ${unit * 6}`
  }
});

const Setup = ({ match }) => {
  const [styles, cx] = useStyles(styleSheet);
  const [apikey, setApiKey] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [serverError, setServerError] = React.useState({
    status: false,
    msg: ""
  });
  const [addNRFAccount] = useMutation(ADD_NRF_ACCOUNT);

  const handleSubmit = e => {
    e.preventDefault();
    if (apikey) {
      setLoading(true);
      addNRFAccount({
        variables: {
          apikey
        }
      })
        .then(() => {
          history.push("/docs");
        })
        .catch(error => {
          const msg = error.graphQLErrors[0].message;
          setServerError({
            status: true,
            msg: msg
          });
          setLoading(false);
        });
    }
  };

  return (
    <div className={cx(styles.modal)}>
      <Header
        logoDark
        actions={
          <>
            <Text>
              <Button href="/login" small inverted>
                Go back to sign in
              </Button>
            </Text>
          </>
        }
      />
      <div className={cx(styles.modal_open)}>
        <div className={cx(styles.model_presentation)}>
          <Spacing vertical={3}>
            <Title level={1}>Connect your nRF Connect for Cloud Account</Title>
            <Content xxsmall>
              {serverError.status && (
                <Spacing bottom={4}>
                  <Alert title={serverError.msg} danger full hideStatusIcon />
                </Spacing>
              )}
              <form>
                <Spacing bottom={2}>
                  <Input
                    name="apikey"
                    label="nRF Connect for Cloud API Key"
                    labelDescription={
                      <Link
                        href="https://nrfcloud.com/#/account"
                        external
                        openInNewWindow
                      >
                        Access your nRF Connect for Cloud API key here
                      </Link>
                    }
                    large
                    type="text"
                    value={apikey}
                    onChange={value => setApiKey(value)}
                    autoComplete="apikey"
                    noSpacing
                    errorMessage="nRF Connect for Cloud API key is needed"
                  />
                </Spacing>
                <Spacing>
                  <Button
                    loading={loading}
                    onClick={handleSubmit}
                    disabled={!apikey}
                  >
                    Add nRF Connect for Cloud API Key
                  </Button>
                </Spacing>
              </form>
            </Content>
          </Spacing>
        </div>
      </div>
    </div>
  );
};

export default Setup;
