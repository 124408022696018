import React from "react";
import _pt from "prop-types";
import useStyles from "../../core/src/hooks/useStyles";
import useTheme from "../../core/src/hooks/useTheme";
import Spacing from "../../core/src/components/Spacing";
import ProfilePhoto from "../../core/src/components/ProfilePhoto";
import Dropdown from "../../core/src/components/Dropdown";
import Menu, { Item } from "../../core/src/components/Menu";
import { changeThemeColors, getRolePermissions } from "../../helpers";
import history from "../../history";
import Text from "../../core/src/components/Text";
import Button from "../../core/src/components/Button";
import Link from "../../core/src/components/Link";
import List, { Item as ListItem } from "../../core/src/components/List";

const styleSheet = ({ unit, ui }) => ({
  upgrade_dropdown: {
    background: "#FFFFFF",
    boxShadow: "10px 5px 5px red",
    width: unit * 44,
    height: "100%",
    boxSizing: "border-box",
    borderRadius: "4px",
    position: "relative",
    zIndex: "100"
  },
  upgrade_dropdown_section: {
    padding: `${unit * 4}px ${unit * 4}px ${unit * 3}px`
  }
});

const UpgradeDropdown = ({ onClickOutside, visible, onClick }) => {
  const [styles, cx] = useStyles(styleSheet);

  return (
    <div className={cx(styles.upgrade_dropdown)}>
      <div className={cx(styles.upgrade_dropdown_section)}>
        <Text bold>Upgrade to production-ready device management</Text>
        <Spacing top={2} bottom={2}>
          <List ordered>
            <ListItem spacious decoration>
              <Text small leading="tight">
                FOTA updates for BLE and LTE-M devices
              </Text>
            </ListItem>
            <ListItem spacious decoration>
              <Text small leading="tight">
                Remotely control and gain insight on your products
              </Text>
            </ListItem>
            <ListItem spacious decoration>
              <Text small leading="tight">
                Keep your product competitive with valuable upgrades
              </Text>
            </ListItem>
            <ListItem spacious decoration>
              <Text small leading="tight">
                Reduce your development load with production-ready tools
              </Text>
            </ListItem>
          </List>
        </Spacing>
      </div>
    </div>
  );
};

UpgradeDropdown.propTypes = {
  children: _pt.node
};

UpgradeDropdown.defaultProps = {
  children: null
};

export default UpgradeDropdown;
