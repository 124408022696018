import React from "react";
import useStyles from "../../../core/src/hooks/useStyles";
import Header from "../../../components/Header";
import Title from "../../../core/src/components/Title";
import Spacing from "../../../core/src/components/Spacing";
import Text from "../../../core/src/components/Text";
import AdaptiveGrid from "../../../core/src/components/AdaptiveGrid/index";
import Content from "../../../components/Content";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { ADD_NRF_ACCOUNT } from "../queries";
import Button from "../../../core/src/components/Button";
import Input from "../../../core/src/components/Input";
import Link from "../../../core/src/components/Link";
import Alert from "../../../core/src/components/Alert";
import history from "../../../history";

const styleSheet = ({ unit }) => ({
  modal: {
    position: "fixed",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    zIndex: 3,
    visibility: "visible",
    opacity: 1,
    willChange: "opacity, visibility",
    transition: "visibility 0.2s ease 0s, opacity 0.2s ease 0s",
    background: "white"
  },
  modal_open: {
    overflowY: "auto",
    transform: "none",
    willChange: "transform",
    position: "fixed",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.2s cubic-bezier(0.2, 0.91, 0.85, 0.96) 0s"
  },
  model_presentation: {
    overflow: "unset",
    width: "100%",
    boxSizing: "border-box",
    position: "relative",
    textAlign: "center",
    maxHeight: "100vh",
    padding: `0 ${unit * 6}`
  }
});

const Message = ({ match }) => {
  const [styles, cx] = useStyles(styleSheet);

  return (
    <div className={cx(styles.modal)}>
      <Header
        logoDark
        actions={
          <>
            <Text>
              <Button href="/login" small inverted>
                Go back to sign in
              </Button>
            </Text>
          </>
        }
      />
      <div className={cx(styles.modal_open)}>
        <div className={cx(styles.model_presentation)}>
          <Spacing vertical={3}>
            <Title level={1}>Connect your portal to nRF Cloud</Title>
            <Text large>
              Please contact your portal admin to connect your portal to nRF
              Cloud
            </Text>
            <Content xxsmall>
              <div>
                <Text>
                  <Button inline href="/login">
                    Go back to Sign in
                  </Button>
                  <Spacing inline horizontal={1}></Spacing>
                  <Button
                    inline
                    href="portal.obvious.xyz/team/members"
                    inverted
                  >
                    Go to portal
                  </Button>
                </Text>
              </div>
            </Content>
          </Spacing>
        </div>
      </div>
    </div>
  );
};

export default Message;
