import React from "react";
import _pt from "prop-types";
import useStyles from "../../../../core/src/hooks/useStyles";

const styleSheet = ({ ui, unit, aside }) => ({
  aside: {
    flexGrow: 0,
    flexShrink: 0,
    padding: unit * 2,
    background: aside.background,
    height: "100%"
  },
  aside_after: {
    borderLeft: ui.border
  },

  aside_before: {
    borderRight: ui.border
  },

  aside_noPadding: {
    padding: 0
  },

  aside_scrollable: {
    overflowY: "auto",
    maxHeight: "100%"
  }
});

const Aside = ({
  after,
  before,
  children,
  noPadding,
  scrollable,
  width,
  color,
  gradient,
  className
}) => {
  const [styles, cx] = useStyles(styleSheet);

  return (
    <aside
      className={cx(
        className,
        styles.aside,
        after && styles.aside_after,
        before && styles.aside_before,
        noPadding && styles.aside_noPadding,
        scrollable && styles.aside_scrollable,
        { width },
        { background: color },
        gradient && {
          background: `linear-gradient(180deg, ${gradient[0]} 0%, ${gradient[1]} 100%)`
        }
      )}
    >
      {children}
    </aside>
  );
};

Aside.propTypes = {
  after: _pt.bool,
  before: _pt.bool,
  children: _pt.any.isRequired,
  noPadding: _pt.bool,
  scrollable: _pt.bool,
  width: _pt.oneOfType([_pt.number, _pt.string])
};

Aside.defaultProps = {
  after: false,
  before: false,
  noPadding: false,
  scrollable: false,
  width: 64
};

export default Aside;
