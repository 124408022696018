import gql from "graphql-tag";

const GET_USER = gql`
  {
    user @client {
      firstName
      email
    }
  }
`;

export { GET_USER };
