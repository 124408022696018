import React from "react";
import _pt from "prop-types";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { GET_FIRMWARE } from "../queries";
import { GET_PRODUCT } from "../../queries";
import useStyles from "../../../../core/src/hooks/useStyles";
import useTheme from "../../../../core/src/hooks/useTheme";
import PageHeader from "../../../../components/PageHeader";
import Breadcrumbs, {
  Breadcrumb
} from "../../../../core/src/components/Breadcrumbs";
import Text from "../../../../core/src/components/Text";
import LogoLoader from "../../../../core/src/components/LogoLoader";
import Row from "../../../../core/src/components/Row";
import Title from "../../../../core/src/components/Title";
import Spacing from "../../../../core/src/components/Spacing";
import DateTime from "../../../../core/src/components/DateTime";
import Layout from "../../../../layout/src/components/Layout";
import MainMenu from "../../../../components/MainMenu";
import Content from "../../../../components/Content";
import ProductBreadcrumb from "../../../../components/ProductBreadcrumb";
import { convertIntegerToVersion } from "../../../../helpers";

const styleSheet = ({ unit }) => ({
  row_wrapper: {
    padding: unit * 1.5,
    backgroundColor: "#ffffff",
    borderRadius: unit,
    marginBottom: unit
  },
  options: {
    display: "flex",
    alignItems: "center"
  },
  options_items: {
    marginRight: unit * 4
  }
});

const FirmwareDetail = ({ match }) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [productID] = React.useState(match.params.product);
  const [firmwareID] = React.useState(match.params.firmware);
  const { loading, data } = useQuery(GET_FIRMWARE, {
    variables: { id: firmwareID },
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });

  const { loading: productLoading, data: productData } = useQuery(GET_PRODUCT, {
    variables: { id: productID },
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });

  return (
    <Layout
      noPadding
      noMaxwidth
      relative
      before={
        <div className={cx(styles.flex)}>
          <MainMenu />
        </div>
      }
    >
      <Content>
        {loading ? (
          <LogoLoader />
        ) : (
          <>
            <ProductBreadcrumb product={productData.product}>
              <Breadcrumbs center accessibilityLabel="Breadcrumb">
                <Breadcrumb
                  highlighted
                  label="Firmware"
                  href={`/products/${productID}/firmware`}
                />
                <Breadcrumb hideIcon label={data.firmware.version} />
              </Breadcrumbs>
            </ProductBreadcrumb>
            <Spacing top={2} bottom={4}>
              <Title level={2}>{data.firmware.version}</Title>
              <Text truncated leading="none">
                {data && data.firmware.originalfilename}
              </Text>
            </Spacing>
            <Spacing bottom={theme.unit / 4}>
              <div className={cx(styles.row_wrapper)}>
                <Row>
                  <Title level={3}>Firmware Details</Title>
                  <Spacing vertical={theme.unit / 4}>
                    <Text bold>Update Range</Text>
                    <Text>
                      min{" "}
                      {convertIntegerToVersion(data.firmware.minverfirmware)} to
                      max{" "}
                      {convertIntegerToVersion(data.firmware.maxverfirmware)}
                    </Text>
                  </Spacing>

                  <Spacing bottom={theme.unit / 4}>
                    <Text bold>Date Created</Text>
                    <Text>
                      <DateTime
                        at={new Date(data.firmware.datecreated).getTime()}
                        short
                      />
                    </Text>
                  </Spacing>
                </Row>
              </div>
            </Spacing>
            <Spacing bottom={theme.unit / 4}>
              <div className={cx(styles.row_wrapper)}>
                <Row>
                  <Title level={3}>Comment</Title>
                  <Text>{data.firmware.comment}</Text>
                </Row>
              </div>
            </Spacing>
            <Spacing bottom={theme.unit / 4}>
              <div className={cx(styles.row_wrapper)}>
                <Row>
                  <Title level={3}>Release Notes</Title>
                  <Text>{data.firmware.releasenotes}</Text>
                </Row>
              </div>
            </Spacing>
            <Spacing bottom={theme.unit / 4}>
              <div className={cx(styles.row_wrapper)}>
                <Row>
                  <Title level={3}>Post Update Message</Title>
                  <Text>{data.firmware.postupdatemessage}</Text>
                </Row>
              </div>
            </Spacing>
          </>
        )}
        ;
      </Content>
    </Layout>
  );
};

FirmwareDetail.propTypes = {};

FirmwareDetail.defaultProps = {};

export default withRouter(FirmwareDetail);
