import React from "react";
import _pt from "prop-types";
import uuid from "uuid/v4";
import useStyles, { aesthetic } from "../../core/src/hooks/useStyles";
import useTheme from "../../core/src/hooks/useTheme";
import Text from "../../core/src/components/Text";
import Button from "../../core/src/components/Button";
import Spacing from "../../core/src/components/Spacing";
import Title from "../../core/src/components/Title";
import IconNordic from "../../icons/src/general/IconNordic";

const styleSheet = ({ color, unit }) => ({
  features_banner: {
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 0,
    left: 0,
    width: 57 * unit,
    height: " 100%",
    textAlign: "center",
    overflow: "hidden",
    background:
      "url(/static/images/obv_super_plugin_feature.jpg) 0% 0% / cover rgba(36, 40, 56, 0.45)",
    "@media": {
      "(min-height: 600px)": {
        position: "fixed"
      },
      "(min-width: 1024px)": {
        display: "flex"
      }
    }
  },
  features_banner_content: {
    padding: `0px ${unit * 6}px`
  },
  features_banner_header_wrap: {
    marginBottom: unit * 4
  },
  features_banner_footer: {
    position: "absolute",
    bottom: unit * 5,
    left: unit * 7,
    right: unit * 7
  },
  features_banner_footer_icons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});

const FeaturesBanner = ({ location }) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [id] = React.useState(uuid());

  return (
    <div className={cx(styles.features_banner)}>
      <div className={cx(styles.features_banner_content)}>
        <div className={cx(styles.features_banner_header_wrap)}>
          <Spacing bottom={2}>
            <Title level={3} inverted>
              The Obvious Plugin for nRF Connect for Cloud
            </Title>
          </Spacing>
          <Text inline inverted>
            Power-up your nRF Connect for Cloud cellular devices with fleet
            firmware rollouts, simple rollbacks, and multi-user support. Take
            the first step from prototype to pro with{" "}
            <Text inline inverted bold>
              obvıous.
            </Text>
          </Text>
        </div>
        <div className={cx(styles.features_banner_footer)}>
          <Text uppercased bold>
            In partnership with
          </Text>
          <Spacing top={2}>
            <div className={cx(styles.features_banner_footer_icons)}>
              <IconNordic width={142} height={"auto"} decorative />
            </div>
          </Spacing>
        </div>
      </div>
    </div>
  );
};

FeaturesBanner.propTypes = {};

FeaturesBanner.defaultProps = {};

export default FeaturesBanner;
