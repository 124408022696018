import React from "react";
import _pt from "prop-types";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { GET_USER } from "./queries";
import useStyles from "../../core/src/hooks/useStyles";
import useTheme from "../../core/src/hooks/useTheme";
import Spacing from "../../core/src/components/Spacing";
import ProfilePhoto from "../../core/src/components/ProfilePhoto";
import Dropdown from "../../core/src/components/Dropdown";
import Menu, { Item } from "../../core/src/components/Menu";
import { changeThemeColors, getRolePermissions } from "../../helpers";
import history from "../../history";
import Text from "../../core/src/components/Text";
import Button from "../../core/src/components/Button";
import Link from "../../core/src/components/Link";
import UpgradeDropdown from "../UpgradeDopdown";
import { getInitials } from "../../helpers";

const styleSheet = ({ unit }) => ({
  top_menu: {
    margin: unit * 3,
    position: "relative",
    zIndex: 1,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  top_menu_container: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingLeft: unit * 3,
    paddingRight: unit * 3
  },
  upgrade_dropdown: {
    position: "relative",
    zIndex: 4
  }
});

const TopMenu = ({ dark, location }) => {
  const permissions = getRolePermissions();
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [upgrade, setUpgrade] = React.useState(false);
  const [user, setUser] = React.useState();

  const handleToggle = () => {
    setOpen(!open);
  };
  const handleUpgrade = () => {
    setUpgrade(false);
  };

  React.useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
  }, []);

  return (
    <div className={cx(styles.top_menu)}>
      <div className={cx(styles.top_menu_container)}>
        {/* <Spacing right={4}>
          <div className={cx(styles.upgrade_dropdown)}>
            <Button
              inverted
              borderless
              onMouseOver={() => setUpgrade(true)}
              onMouseLeave={() => setUpgrade(false)}
              href="/upgrade"
            >
              Upgrade account
            </Button>
            {upgrade && (
              <Dropdown
                visible={upgrade}
                onClickOutside={handleUpgrade}
                top={60}
                right={0}
                zIndex={50}
                boxShadow
              >
                <UpgradeDropdown onClick={handleUpgrade} />
              </Dropdown>
            )}
          </div>
        </Spacing> */}
        <div>
          <Spacing>
            <div onClick={handleToggle} className={cx({ cursor: "pointer" })}>
              <ProfilePhoto size={5} background="dark">
                <Text micro bold inverted>
                  {user ? getInitials(user.firstName) : ""}
                </Text>
              </ProfilePhoto>
            </div>
            {open && (
              <Dropdown
                visible
                onClickOutside={handleToggle}
                top={60}
                right={0}
                zIndex={50}
                offset="right"
              >
                <Menu accessibilityLabel="Account Setting Dropdown">
                  <Item href={`/account/profile?next=${location.pathname}`}>
                    Manage Account
                  </Item>
                  <Item
                    danger
                    href={"/login"}
                    onClick={() => {
                      changeThemeColors("#00B1A9");
                      history.push("/login");
                    }}
                  >
                    Log out
                  </Item>
                </Menu>
              </Dropdown>
            )}
          </Spacing>
        </div>
      </div>
    </div>
  );
};

TopMenu.propTypes = {
  children: _pt.node
};

TopMenu.defaultProps = {
  children: null
};

export default withRouter(TopMenu);
