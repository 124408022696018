import gql from "graphql-tag";

const GET_PRODUCT = gql`
  query nrfProduct {
    nrfProduct {
      id
      name
    }
  }
`;

export { GET_PRODUCT };
