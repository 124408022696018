import React from "react";
import _pt from "prop-types";
import uuid from "uuid/v4";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  CREATE_FIRMWARE,
  UPDATE_FIRMWARE,
  GET_FIRMWARES,
  GET_GROUPS
} from "../queries";
import FormActions from "../../../../core/src/components/FormActions";
import Loader from "../../../../core/src/components/Loader";
import Input from "../../../../core/src/components/Input";
import Select from "../../../../core/src/components/Select";
import Divider from "../../../../core/src/components/Divider";
import FileInput from "../../../../core/src/components/FileInput";
import {
  convertVersionToString,
  convertIntegerToVersion
} from "../../../../helpers";

const FirmwareForm = ({ product, selectedFirmware, onClose, ...props }) => {
  const [id] = React.useState(uuid());
  const [loading, setLoading] = React.useState(false);
  const { loading: groupLoading, data, refetch } = useQuery(GET_GROUPS, {
    variables: { productid: product },
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });
  const [firmware, setFirmware] = React.useState({
    version: "",
    minverfirmware: "",
    maxverfirmware: "",
    file: "",
    originalfilename: "",
    group: "",
    comment: "",
    releasenotes: "",
    postupdatemessage: ""
  });
  const [error, setError] = React.useState({
    version: { status: false },
    file: { status: false },
    originalfilename: { status: false },
    maxverfirmware: { status: false },
    minverfirmware: { status: false }
  });
  const [type, setType] = React.useState("create");

  const [createFirmware] = useMutation(CREATE_FIRMWARE, {
    update(
      cache,
      {
        data: {
          createFirmware: { firmware }
        }
      }
    ) {
      const data = cache.readQuery({
        query: GET_FIRMWARES,
        variables: { productid: product }
      });
      data.allFirmware.edges.push({
        node: { ...firmware },
        __typename: "FirmwareNodeEdge"
      });
      cache.writeQuery({
        query: GET_FIRMWARES,
        variables: { productid: product },
        data
      });
    }
  });

  const [updateFirmware] = useMutation(UPDATE_FIRMWARE);

  React.useEffect(() => {
    if (selectedFirmware) {
      setFirmware({
        ...selectedFirmware,
        minverfirmware: convertIntegerToVersion(
          selectedFirmware.minverfirmware
        ),
        maxverfirmware: convertIntegerToVersion(selectedFirmware.maxverfirmware)
      });
      setType("edit");
    }
  }, []);

  const handleSubmit = data => {
    setLoading(true);

    return Promise.resolve(props.onSubmit(data, props)).finally(() => {
      setLoading(false);
    });
  };

  const updateField = (value, event) => {
    setFirmware({
      ...firmware,
      [event.target.name]: value
    });
    setError({
      ...error,
      [event.target.name]: { status: false }
    });
  };

  const validate = e => {
    e.preventDefault();
    let submitError = false;
    var errors = {};
    if (!firmware.version) {
      errors = {
        ...errors,
        version: { status: true }
      };
      submitError = true;
    }

    if (convertVersionToString(firmware.version) instanceof Error) {
      errors = {
        ...errors,
        version: { status: true }
      };
      submitError = true;
    }

    if (!firmware.originalfilename) {
      errors = {
        ...errors,
        file: { status: true }
      };
      submitError = true;
    }

    if (
      firmware.minverfirmware &&
      convertVersionToString(firmware.minverfirmware) instanceof Error
    ) {
      errors = {
        ...errors,
        minverfirmware: { status: true }
      };
      submitError = true;
    }

    if (
      firmware.maxverfirmware &&
      convertVersionToString(firmware.maxverfirmware) instanceof Error
    ) {
      errors = {
        ...errors,
        maxverfirmware: { status: true }
      };
      submitError = true;
    }

    if (submitError) {
      setError({ ...error, ...errors });
      return;
    }

    handleCreate();
  };

  const handleCreate = () => {
    setLoading(true);
    const newFirmware = {
      ...firmware,
      versioncode: convertVersionToString(firmware.version),
      minverfirmware: firmware.minverfirmware
        ? convertVersionToString(firmware.minverfirmware)
        : 0,
      maxverfirmware: firmware.maxverfirmware
        ? convertVersionToString(firmware.maxverfirmware)
        : 999999999
    };
    if (type === "create") {
      createFirmware({
        variables: {
          productid: product,
          ...newFirmware
        },
        optimisticResponse: {
          __typename: "Mutation",
          createFirmware: {
            firmware: {
              id: -1,
              ...newFirmware,
              __typename: "FirmwareNode"
            },
            __typename: "createFirmware"
          }
        }
      })
        .then(res => {
          onClose();
        })
        .catch(error => {
          console.log(error);
          setLoading(false);
        });
    }
    if (type === "edit") {
      updateFirmware({
        variables: {
          productid: product,
          ...newFirmware
        },
        optimisticResponse: {
          __typename: "Mutation",
          createFirmware: {
            firmware: {
              ...newFirmware,
              __typename: "FirmwareNode"
            },
            __typename: "createFirmware"
          }
        }
      }).catch(error => {
        console.log(error);
        setLoading(false);
      });
      onClose();
    }
  };

  const handleFile = value => {
    if (value) {
      let file = value[0];
      if (file) {
        setFirmware({
          ...firmware,
          file: file,
          originalfilename: file.name
        });

        setError({
          ...error,
          file: { status: false }
        });
      }
    }
  };

  return (
    <form
      id={id}
      method="post"
      encType="multipart/form-data"
      onSubmit={handleSubmit}
    >
      {selectedFirmware && (
        <Input
          name="originalfilename"
          label="Current File"
          value={firmware.originalfilename}
          disabled
        />
      )}
      <FileInput
        name="file"
        label={selectedFirmware ? "Change Firmware File" : "Firmware File"}
        onChange={handleFile}
        errorMessage="Firmware file is needed"
        invalid={error.file.status}
      />
      <Input
        name="version"
        label="Version"
        placeholder="1.3.0"
        value={firmware.version}
        onChange={updateField}
        errorMessage="Firmware version needs to follow 111.111.111 version format"
        invalid={error.version.status}
      />
      <Divider label="optional" />
      <Input
        name="minverfirmware"
        label="Min Version Firmware"
        placeholder="1.1.0"
        value={firmware.minverfirmware}
        onChange={updateField}
        errorMessage="Min Version Firmware needs to follow 111.111.111 version format"
        invalid={error.minverfirmware.status}
      />
      <Input
        name="maxverfirmware"
        label="Max Version Firmware"
        placeholder="1.1.0"
        value={firmware.maxverfirmware}
        onChange={updateField}
        errorMessage="Max Version Firmware needs to follow 111.111.111 version format"
        invalid={error.maxverfirmware.status}
      />
      <Select
        name="group"
        label="Select a group"
        labelDescription="Add firmware to selected group"
        disabled={groupLoading}
        onChange={updateField}
        value={firmware.group}
      >
        <option>
          {groupLoading ? "Fetching Groups" : "Please select a group"}
        </option>
        <option value={"base"}>Base</option>
        {data &&
          data.allGroups.edges.map(({ node: group }) => {
            return (
              <option value={group.id} key={group.id}>
                {group.name}
              </option>
            );
          })}
      </Select>
      <FormActions
        continueText={`${type === "create" ? "Upload" : "Update"} firmware`}
        processingText={`${type === "create" ? "Uploading..." : "Updating..."}`}
        processing={loading}
        onCancel={() => onClose()}
        onContinue={validate}
        disabled={!firmware.originalfilename || !firmware.version}
      />
    </form>
  );
};

FirmwareForm.propTypes = {};

FirmwareForm.defaultProps = {};

export default FirmwareForm;
