import React from "react";
import _pt from "prop-types";
import uuid from "uuid/v4";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  CREATE_MANUFACTURE_ACCOUNT,
  VERIFY_REGEISTER_USER_EMAIL,
  CREATE_ACCOUNT
} from "../queries";
import {
  validateEmail,
  changeThemeColors,
  lowercaseNoSpace,
  getAllUrlParams
} from "../../../helpers";
import useStyles, { aesthetic } from "../../../core/src/hooks/useStyles";
import useTheme from "../../../core/src/hooks/useTheme";
import Header from "../../../components/Header";
import Text from "../../../core/src/components/Text";
import Button from "../../../core/src/components/Button";
import Spacing from "../../../core/src/components/Spacing";
import LeftPanel from "../LeftPanel";
import RightPanel from "../RightPanel";
import FeaturesBanner from "../FeaturesBanner";
import Title from "../../../core/src/components/Title";
import history from "../../../history";
import CheckBox from "../../../core/src/components/CheckBox";
import Link from "../../../core/src/components/Link";
import Input from "../../../core/src/components/Input";
import Alert from "../../../core/src/components/Alert";

const styleSheet = ({ color, unit }) => ({
  panelWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    position: "relative",
    width: "100%"
  },
  feature_panel: {
    display: "flex",
    position: "fixed",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    left: 0,
    width: unit * 57,
    minheight: unit * 75,
    height: "100%",
    overflow: "hidden",
    background: "center center / cover no-repeat rgba(36,40,56,0.65)",
    backgroundPosition: "center center",
    backgroundRepeat: "noRepeat"
  },
  obv_logo: {
    position: "absolute",
    top: unit * 5,
    left: unit * 5,
    zIndex: 1
  },
  feature_panel_image: {
    objectFit: "cover",
    height: "100%",
    position: "absolute",
    right: 0,
    top: 0,
    transition: "all 0.3s liner 0s"
  },
  login_content: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  login_content_wrapper: {
    width: "100%",
    maxWidth: unit * 72,
    padding: 0
  },
  form: {
    opacity: 1,
    transition: "all .2s ease-in-out"
  },
  form_start_login: {
    opacity: 0
  },
  logo: {
    position: "absolute",
    zIndex: 10,
    opacity: 1,
    pointerEvents: "none",
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    left: 0
  },
  logo_outer_circle: {
    width: "256px",
    height: "256px",
    backgroundColor: color.core.primary[3],
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "all .2s ease-in-out"
  },
  logo_outer_circle_expand: {
    width: "100vw",
    height: "100vh",
    borderRadius: "0px"
  },
  logo_wrapper: {
    display: "flex",
    alignItems: "flex-end",
    willChange: "transform, opacity",
    opacity: 0,
    transform: "translateY(-100%)",
    transition: "all .4s ease-in-out"
  },
  logo_wrapper_show: {
    transform: "translateY(0)",
    opacity: 1
  },
  logo_inner_circle: {
    width: "128px",
    height: "128px",
    backgroundColor: color.base,
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  logo_inner_circle_expand: {
    width: 200,
    height: "auto",
    backgroundColor: "transparent"
  },
  logo_dot: {
    width: "56px",
    height: "56px",
    backgroundColor: "#000000",
    borderRadius: "100%",
    marginBottom: "16px"
  },
  logo_dot_hide: {
    width: 0,
    height: 0
  },
  logo_company: {
    opacity: 0,
    transition: "all .4s ease-in-out"
  },
  logo_company_show: {
    opacity: 1
  },
  noshow: {
    transition: "all .2s ease-in-out",
    opacity: 1
  },
  show: {
    opacity: 0
  }
});

const Account = ({ location }) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [id] = React.useState(uuid());
  const [queryEmail, setQueryEmail] = React.useState(
    getAllUrlParams(location.search).email
  );
  const [loading, setLoading] = React.useState(false);
  const [serverError, setServerError] = React.useState({
    status: false,
    msg: ""
  });
  const [agreement, setAgreement] = React.useState(false);
  const [verifying, setVerifying] = React.useState(true);
  const [company, setCompany] = React.useState({
    firstName: "",
    email: "",
    password: "",
    apikey: ""
  });

  const [error, setError] = React.useState({
    firstName: { status: false },
    email: { status: false },
    password: { status: false },
    agreement: { status: false },
    apikey: { status: false }
  });

  const [createManufactureAccount] = useMutation(CREATE_MANUFACTURE_ACCOUNT);
  const [verify] = useMutation(VERIFY_REGEISTER_USER_EMAIL);
  const [create] = useMutation(CREATE_ACCOUNT);

  React.useLayoutEffect(() => {
    if (queryEmail) {
      verify({
        variables: {
          email: queryEmail
        }
      }).then(
        ({
          data: {
            verifyRegisterUserEmail: { success }
          }
        }) => {
          setVerifying(false);
          setCompany({
            ...company,
            email: queryEmail
          });
          if (!success) {
            setServerError({
              status: true,
              msg:
                "This email has not been invited to join an existing team, you can create your own team or contact team member to send invite again."
            });
            setQueryEmail(null);
          }
        }
      );
    } else {
      setVerifying(false);
    }
  }, []);

  const updateField = (value, event) => {
    setCompany({
      ...company,
      [event.target.name]: value
    });
    setError({
      ...error,
      [event.target.name]: { status: false }
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    let submitError = false;
    var errors = {};

    if (!agreement) {
      errors = {
        ...errors,
        agreement: { status: true }
      };
      submitError = true;
    }

    if (!company.apikey && !queryEmail) {
      errors = {
        ...errors,
        apikey: { status: true }
      };
      submitError = true;
    }

    if (!company.email) {
      errors = {
        ...errors,
        email: { status: true }
      };
      submitError = true;
    }

    if (!validateEmail(company.email)) {
      errors = {
        ...errors,
        email: { status: true }
      };
      submitError = true;
    }

    if (!company.firstName) {
      errors = {
        ...errors,
        firstName: { status: true }
      };
      submitError = true;
    }

    if (!company.password || company.password.length < 8) {
      errors = {
        ...errors,
        password: { status: true }
      };
      submitError = true;
    }

    if (submitError) {
      setError({ ...error, ...errors });
      return;
    }
    setLoading(true);
    if (queryEmail) {
      create({
        variables: {
          ...company
        }
      })
        .then(
          ({
            data: {
              registerUser: { success }
            }
          }) => {
            if (success) {
              history.push({
                pathname: "/login",
                state: { accountCreated: true }
              });
            } else {
              setServerError({
                status: true,
                msg: "This email already exist in our system"
              });
            }
            setLoading(false);
          }
        )
        .catch(res => {
          setServerError({
            status: true,
            msg: "Email address already in use."
          });
          setError({
            ...error,
            email: { status: true }
          });
          setLoading(false);
        });
    } else {
      createManufactureAccount({
        variables: {
          ...company,
          company: `${company.firstName} Company`
        }
      })
        .then(res => {
          history.push({
            pathname: "/login",
            state: {
              success: true,
              msg: "Your company portal has been created."
            }
          });
          setLoading(false);
        })
        .catch(res => {
          const msg = res.graphQLErrors[0].message;
          setServerError({
            status: true,
            msg: msg
          });
          if (msg.includes("Email")) {
            setError({
              ...error,
              email: { status: true }
            });
          }
          if (msg.includes("API")) {
            setError({
              ...error,
              apikey: { status: true }
            });
          }

          setLoading(false);
        });
    }
  };

  return (
    !verifying && (
      <div className={cx(styles.panelWrapper)}>
        <Header
          actions={
            <>
              <Text>
                Already have an account?
                <Spacing inline left={3}>
                  <Button href="/signin" small inverted>
                    Sign in
                  </Button>
                </Spacing>
              </Text>
            </>
          }
        />
        <LeftPanel>
          <FeaturesBanner />
        </LeftPanel>
        <RightPanel>
          <div className={cx(styles.login_content)}>
            <div className={cx(styles.login_content_wrapper)}>
              <Spacing bottom={4}>
                <div>
                  <Title level={2}>Start managing at scale</Title>
                  <Text>Free, forever.</Text>
                </div>
              </Spacing>
              <form
                id={id}
                method="post"
                encType="multipart/form-data"
                onSubmit={handleSubmit}
              >
                {serverError.status && (
                  <Spacing bottom={4}>
                    <Alert title={serverError.msg} danger full hideStatusIcon />
                  </Spacing>
                )}
                <Spacing bottom={2}>
                  <Input
                    name="email"
                    label="Email"
                    large
                    type="email"
                    value={company.email}
                    onChange={updateField}
                    autoComplete="email"
                    noSpacing
                    invalid={error.email.status}
                    errorMessage="Email is required"
                  />
                </Spacing>
                <Spacing bottom={2}>
                  <Input
                    name="firstName"
                    label="Your Name"
                    large
                    value={company.firstName}
                    onChange={updateField}
                    autoComplete="firstName"
                    noSpacing
                    invalid={error.firstName.status}
                    errorMessage="First name is required"
                  />
                </Spacing>
                <Spacing bottom={2}>
                  <Input
                    name="password"
                    label="Password"
                    labelDescription="8+ characters"
                    large
                    type="password"
                    value={company.password}
                    onChange={updateField}
                    autoComplete="password"
                    noSpacing
                    invalid={error.password.status}
                    errorMessage="Password needs to be 8+ characters"
                  />
                </Spacing>
                {!queryEmail && (
                  <Spacing bottom={2}>
                    <Input
                      name="apikey"
                      label="nRF Connect for Cloud API Key"
                      labelDescription={
                        <Link
                          href="https://nrfcloud.com/#/account"
                          external
                          openInNewWindow
                        >
                          Access your nRF Connect for Cloud API key here
                        </Link>
                      }
                      large
                      type="text"
                      value={company.apikey}
                      onChange={updateField}
                      autoComplete="apikey"
                      noSpacing
                      invalid={error.apikey.status}
                      errorMessage="nRF Connect for Cloud API key is needed"
                    />
                  </Spacing>
                )}
                <Spacing vertical={theme.unit / 2}>
                  <div
                    className={cx({
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between"
                    })}
                  >
                    <div>
                      <CheckBox
                        name="agreement"
                        label="Agreement"
                        hideLabel
                        onChange={() => setAgreement(!agreement)}
                        checked={agreement}
                        invalid={error.agreement.status}
                      />
                    </div>
                    <Spacing left={1}>
                      <Text small>
                        By creating an account, you agree to the{" "}
                        <Link openInNewWindow href="/static/pdf/terms.pdf">
                          Terms and Conditions
                        </Link>{" "}
                        and{" "}
                        <Link openInNewWindow href="/static/pdf/privacy.pdf">
                          Privacy policy
                        </Link>
                        .
                      </Text>
                    </Spacing>
                  </div>
                </Spacing>
                <Spacing vertical={theme.unit / 2}>
                  <Button
                    large
                    block
                    disabled={
                      !company.email ||
                      !company.firstName ||
                      !company.password ||
                      !agreement ||
                      (!company.apikey && !queryEmail)
                    }
                    onClick={handleSubmit}
                    loading={loading}
                    type="submit"
                  >
                    Jump in
                  </Button>
                </Spacing>
              </form>
            </div>
          </div>
        </RightPanel>
      </div>
    )
  );
};

Account.propTypes = {};

Account.defaultProps = {};

export default Account;
