import gql from "graphql-tag";

const GET_GROUPS = gql`
  query allGroups($productid: ID!) {
    allGroups(productid: $productid) {
      edges {
        node {
          id
          name
          description
          priority
        }
      }
    }
  }
`;

const DESTROY_GROUP = gql`
  mutation destroyGroup($productid: ID!, $id: ID!) {
    destroyGroup(data: { productid: $productid, id: $id }) {
      group {
        id
      }
    }
  }
`;

const GET_GROUP = gql`
  query group($id: ID!) {
    group(id: $id) {
      id
      name
      priority
      description
      membersCount
    }
  }
`;

const GET_FIRMWARE_ASSIGNMENT = gql`
  query allFirmwareAssignments($groupid: ID, $productid: ID!) {
    allFirmwareAssignments(groupid: $groupid, productid: $productid) {
      edges {
        node {
          id
          active
          comment
          minverandroid
          minverios
          firmware: firmwareid {
            id
            version
          }
        }
      }
    }
  }
`;

const GET_BASE_FIRMWARE_ASSIGNMENT = gql`
  query allBaseFirmwareAssignments($productid: ID!) {
    allBaseFirmwareAssignments(productid: $productid) {
      edges {
        node {
          id
          active
          comment
          minverandroid
          minverios
          firmware: firmwareid {
            id
            version
          }
        }
      }
    }
  }
`;

const GET_GROUP_DEVICES = gql`
  query allGroupDevices($groupid: ID, $productid: ID!) {
    allGroupDevices(groupid: $groupid, productid: $productid) {
      edges {
        node {
          id
          memberid
        }
      }
    }
  }
`;

const GET_DEVICES_NOT_IN_ANY_GROUP = gql`
  query allDevicesNotInAnyGroup($productid: ID!, $device: String) {
    allDevicesNotInAnyGroup(productid: $productid, device: $device) {
      edges {
        node {
          id
          serialnumber
        }
      }
    }
  }
`;

const DESTROY_GROUP_DEVICE = gql`
  mutation destroyGroupMember($productid: ID!, $groupid: ID!, $id: ID!) {
    destroyGroupMember(
      groupMemberData: { productid: $productid, groupid: $groupid, id: $id }
    ) {
      groupMember {
        id
      }
    }
  }
`;

const DESTROY_GROUP_FIRMWARE_ASSIGNMENT = gql`
  mutation destroyGroupFirmwareAssignment(
    $productid: ID!
    $groupid: ID
    $id: ID!
  ) {
    destroyGroupFirmwareAssignment(
      data: { productid: $productid, groupid: $groupid, id: $id }
    ) {
      assignment {
        id
      }
    }
  }
`;

const UPDATE_GROUP_FIRMWARE_ASSIGNMENT = gql`
  mutation updateNrfGroupFirmwareAssignment(
    $id: ID
    $productid: ID!
    $firmwareid: ID
    $groupid: ID
    $minverios: Int
    $minverandroid: Int
    $comment: String
    $active: Boolean
  ) {
    updateNrfGroupFirmwareAssignment(
      data: {
        id: $id
        productid: $productid
        firmwareid: $firmwareid
        groupid: $groupid
        minverandroid: $minverandroid
        minverios: $minverios
        active: $active
        comment: $comment
      }
    ) {
      assignment {
        id
        active
        comment
        minverandroid
        minverios
        firmware: firmwareid {
          id
          version
        }
      }
    }
  }
`;

const GET_FIRMWARE = gql`
  query allFirmware($productid: ID!) {
    allFirmware(productid: $productid) {
      edges {
        node {
          id
          version
        }
      }
    }
  }
`;

const CHECK_DEVICES = gql`
  query allDevices($productid: ID!, $serialnumber: String) {
    allDevices(productid: $productid, serialnumber: $serialnumber) {
      edges {
        node {
          id
          serialnumber
          featuresCount
        }
      }
    }
  }
`;

const CREATE_GROUP_DEVICE = gql`
  mutation createGroupMember(
    $productid: ID!
    $groupid: ID!
    $memberid: String!
  ) {
    createGroupMember(
      groupMemberData: {
        productid: $productid
        groupid: $groupid
        memberid: $memberid
      }
    ) {
      groupMember {
        id
        memberid
      }
    }
  }
`;

const CREATE_GROUP = gql`
  mutation createGroup(
    $productid: ID!
    $name: String!
    $description: String!
    $priority: Int!
  ) {
    createGroup(
      groupData: {
        productid: $productid
        name: $name
        description: $description
        priority: $priority
      }
    ) {
      group {
        id
        name
        description
        priority
      }
    }
  }
`;

const UPDATE_GROUP = gql`
  mutation updateGroup(
    $id: ID!
    $name: String!
    $description: String!
    $priority: Int!
  ) {
    updateGroup(
      data: {
        id: $id
        name: $name
        description: $description
        priority: $priority
      }
    ) {
      group {
        id
        name
        description
        priority
      }
    }
  }
`;

const GET_DEVICES_NOT_IN_GROUP = gql`
  query allDevicesNotInGroup($groupid: ID!, $productid: ID!, $device: String) {
    allDevicesNotInGroup(
      groupid: $groupid
      productid: $productid
      device: $device
      first: 100
    ) {
      edges {
        node {
          id
          serialnumber
        }
      }
    }
  }
`;

const CREATE_GROUP_FIRMWARE_ASSIGNMENT = gql`
  mutation createGroupFirmwareAssignment(
    $productid: ID!
    $firmwareid: ID!
    $groupid: ID
    $minverios: Int
    $minverandroid: Int
    $comment: String
  ) {
    createGroupFirmwareAssignment(
      data: {
        productid: $productid
        firmwareid: $firmwareid
        groupid: $groupid
        minverandroid: $minverandroid
        minverios: $minverios
        comment: $comment
      }
    ) {
      assignment {
        id
        active
        comment
        minverandroid
        minverios
        firmware: firmwareid {
          id
          version
        }
      }
    }
  }
`;

const GET_JOBS = gql`
  query allManufacturerJobs($productid: ID!, $groupid: ID) {
    allManufacturerJobs(productid: $productid, groupid: $groupid) {
      edges {
        node {
          id
          jobid
          fileid
          status
          datecreated
          datecompleted
          successcount
          pendingcount
          failedcount
        }
      }
    }
  }
`;

const CREATE_JOB = gql`
  mutation createJob($groupid: ID) {
    createJob(groupid: $groupid) {
      job {
        id
      }
    }
  }
`;

const CANCEL_JOB = gql`
  mutation cancelJob($jobid: ID) {
    cancelJob(jobid: $jobid) {
      success
    }
  }
`;

export {
  GET_DEVICES_NOT_IN_ANY_GROUP,
  GET_DEVICES_NOT_IN_GROUP,
  GET_GROUP,
  GET_GROUPS,
  GET_FIRMWARE,
  GET_FIRMWARE_ASSIGNMENT,
  GET_BASE_FIRMWARE_ASSIGNMENT,
  CREATE_GROUP,
  CREATE_GROUP_DEVICE,
  CREATE_GROUP_FIRMWARE_ASSIGNMENT,
  CHECK_DEVICES,
  UPDATE_GROUP,
  UPDATE_GROUP_FIRMWARE_ASSIGNMENT,
  DESTROY_GROUP_FIRMWARE_ASSIGNMENT,
  DESTROY_GROUP_DEVICE,
  GET_GROUP_DEVICES,
  DESTROY_GROUP,
  GET_JOBS,
  CREATE_JOB,
  CANCEL_JOB
};
