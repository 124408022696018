import gql from "graphql-tag";

const GET_MANUFACTURER_CONFIGS = gql`
  query allManufacturerConfigs {
    allManufacturerConfigs {
      edges {
        node {
          id
          apikey
        }
      }
    }
  }
`;

const UPDATE_MANUFACTURER_CONFIGS = gql`
  mutation updateManufacturerIntegrationConfig($id: ID!, $value: String!) {
    updateManufacturerIntegrationConfig(data: { id: $id, value: $value }) {
      config {
        id
        apikey
      }
    }
  }
`;

export { GET_MANUFACTURER_CONFIGS, UPDATE_MANUFACTURER_CONFIGS };
