export const breakpoints = {
  xxsmall: 536,
  xsmall: 767,
  small: 980,
  medium: 1048,
  large: 1458
};
export const responsive = {
  xxsmall: "(max-width: " + breakpoints.xxsmall + "px)",
  xsmall: "(max-width: " + breakpoints.xsmall + "px)",
  small: "(max-width: " + breakpoints.small + "px)",
  medium: "(min-width: " + breakpoints.medium + "px)",
  large: "(min-width: " + breakpoints.large + "px)"
};
