import React from "react";
import _pt from "prop-types";
import useStyles from "../../core/src/hooks/useStyles";
import Link from "../../core/src/components/Link";
import ProfilePhoto from "../../core/src/components/ProfilePhoto";
import { getInitials } from "../../helpers";
import Spacing from "../../core/src/components/Spacing";
import DirectionalIcon from "../../core/src/components/DirectionalIcon";
import IconChevronRight from "../../icons/src/interface/IconChevronRight";
import IconChevronLeft from "../../icons/src/interface/IconChevronLeft";

const styleSheet = ({ unit, font, color }) => ({
  flex_center: {
    display: "flex",
    alignItems: "center"
  },
  flex_grow: {
    flexGrow: 1,
    width: "100%"
  }
});

const ProductBreadcrumb = ({ children, product }) => {
  const [styles, cx] = useStyles(styleSheet);

  return (
    <Spacing bottom={4}>
      <div className={cx(styles.flex_center)}>
        <Link href={`/product`} noUnderline>
          <div className={cx(styles.flex_center)}>
            <ProfilePhoto background="dark">nRF</ProfilePhoto>
            <Spacing horizontal={1}>
              <DirectionalIcon
                direction="right"
                left={IconChevronLeft}
                right={IconChevronRight}
                size={24}
                decorative
              />
            </Spacing>
          </div>
        </Link>
        {children}
      </div>
    </Spacing>
  );
};

ProductBreadcrumb.propTypes = {};

ProductBreadcrumb.defaultProps = {};

export default ProductBreadcrumb;
