import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_JOBS, CREATE_JOB } from "../../queries";
import useStyles from "../../../../../core/src/hooks/useStyles";
import Text from "../../../../../core/src/components/Text/index";
import Row from "../../../../../core/src/components/Row";
import Button from "../../../../../core/src/components/Button";
import Modal from "../../../../../core/src/components/Modal";
import Form from "./Form";
import Job from "./Job";
import LogoLoader from "../../../../../core/src/components/LogoLoader";
import AppLoader from "../../../../../core/src/components/AppLoader";

const styleSheet = ({ color, unit }) => ({
  header: {
    paddingLeft: unit * 1.5,
    paddingRight: unit * 1.5,
    paddingBottom: unit * 1.5
  },
  header_children: {
    display: "flex",
    alignItems: "center"
  },
  header_items: {
    marginRight: unit * 4
  },
  options: {
    width: 24
  },
  options_bar: {
    display: "flex",
    paddingBottom: unit * 4,
    width: "100%",
    justifyContent: "flex-end"
  }
});

const GroupJobsList = ({ children, product, group, refetch }) => {
  const [styles, cx] = useStyles(styleSheet);
  const [visable, setVisable] = React.useState(false);
  const { loading, data } = useQuery(GET_JOBS, {
    variables: {
      groupid: group !== "base" ? group : null,
      productid: product
    },
    options: () => ({ fetchPolicy: "network-only" })
  });

  return (
    <AppLoader
      fetched={!loading}
      centered
      small
      loadingText="Loading jobs"
      failureText="Failed to load jobs"
    >
      <div className={cx(styles.header)}>
        <Row
          after={
            <div className={cx(styles.header_children)}>
              <div className={cx(styles.header_items)}>
                <Text small>Date Created</Text>
              </div>
              <div className={cx(styles.options)} />
            </div>
          }
        >
          <Text small>Firmware Update Status</Text>
        </Row>
      </div>
      {data &&
        data.allManufacturerJobs.edges.map(({ node: job }) => (
          <Job key={job.id} job={job} product={product} group={group}>
            <Text bold>{job.status}</Text>
          </Job>
        ))}
      {visable && (
        <Modal
          title={"Add a firmware assignment to group"}
          onClose={() => setVisable(false)}
        >
          <Form
            product={product}
            group={group}
            refetch={() => refetch()}
            onClose={() => {
              setVisable(false);
            }}
          />
        </Modal>
      )}
    </AppLoader>
  );
};

export { Job };
export default GroupJobsList;
